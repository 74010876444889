import './EvalSubmit.scss';

export default function EvalSubmit({disabled, submitted, onClickHandler}) {
  return (
    <div className="evalSubmit">
      {!submitted && (
        <button
          disabled={disabled}
          onClick={() => {
            onClickHandler();
          }}>
          SUBMIT YOUR EVALUATION
          <img src="/done.svg" alt="" />
        </button>
      )}
      {!submitted && disabled && (
        <p>You must complete the evaluation before submitting.</p>
      )}
      {submitted && <p>This evaluation has been submitted.</p>}
    </div>
  );
}
