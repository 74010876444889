import './Dashboard.scss';
import Header from '../header/Header';
import Message from '../message/Message';
import PerfChart from './perfChart/PerfChart';
import Container from '../container/Container';

import React, {useState, useEffect} from 'react';

import {firestore} from '../../firebase';

import {useAuth} from '../../contexts/AuthContext';

export default function Dashboard() {
  const {currentUser, currentUserData} = useAuth();
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (currentUser) {
      setUserEmail(currentUser.email);
    }
  }, [currentUser]);

  // Related to relevant data
  const [relevantDataset, setRelevantDataset] = useState();

  useEffect(() => {
    if (currentUser && currentUserData && !relevantDataset) {
      (async () => {
        const relevantRef = firestore
          .collection('/users/' + currentUserData.canonical + '/users')
          .where(`relevant_to`, `array-contains`, userEmail);
        const relevantUsers = await relevantRef.get();

        let relevantReturned = [];
        relevantUsers.docs.forEach((doc) => {
          relevantReturned.push({
            id: doc.id,
            data: doc.data(),
          });
        });

        console.log('---RELEVANT USERS---');
        console.log(relevantReturned);
        console.log('---END RELEVANT USERS---');

        relevantReturned = relevantReturned.map((entry) => {
          return {
            id: entry.id,
            role: entry.data.role,
            fill:
              entry.data.fill && entry.data.fill[userEmail]
                ? entry.data.fill[userEmail]
                : null,
          };
        });
        setRelevantDataset(relevantReturned);
      })();
    }
  }, [currentUser, currentUserData, relevantDataset]);

  useEffect(() => {
    console.log('---RELEVANT---');
    console.log(relevantDataset);
    console.log('---END RELEVANT---');
  }, [relevantDataset]);

  return (
    <>
      <Header />
      <Container>
        <div className="dashboard-container">
          {currentUserData && (
            <Message>
              <h2 className="onlyprint">{currentUserData.name}</h2>
              <h2 className="noprint">Welcome, {currentUserData.name}!</h2>
              <p className="noprint">
                In this dashboard, you can review your performance and compare
                it with ROMAR VAULT results.
              </p>
            </Message>
          )}
          {relevantDataset && (
            <div className="dashboard-wrapper">
              <PerfChart
                relevantData={relevantDataset}
                displayCompletionChart={true}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
