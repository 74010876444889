import styles from './Popup.module.scss';

export default function Popup({children, displayed, dismissed, limit}) {
	return (
		<>
			{displayed && !dismissed && (
				<div className={styles.popupBg}>
					<div className={styles.popup} style={{maxWidth: limit ? limit : ''}}>
						{children}
					</div>
				</div>
			)}
		</>
	);
}
