import React, {useState, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';

import {firestore} from '../../firebase';
import './Query.scss';

import Header from '../header/Header';
import Loading from '../loading/Loading';

import Conditions from './conditions/Conditions';
import Output from './output/Output';
import QueryInput from './queryInput/QueryInput';
import QueryBrowser from './browser/QueryBrowser';
import Message from '../message/Message';
import Container from '../container/Container';
import UniversalQuery from './universal/UniversalQuery';

export default function Query() {
  let {command, argument} = useParams();
  const location = useLocation();
  const [determinedInit, setDeterminedInit] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [query, setQuery] = useState([]);
  const [querySetter, setQuerySetter] = useState([{key: 'Company', value: ''}]);

  const [queryOutput, setQueryOutput] = useState({});

  const [printMode, setPrintMode] = useState(false);

  {
    // Managing location
    const [lastKnownLocation, setLastKnownLocation] = useState();
    useEffect(() => {
      if (location.pathname !== lastKnownLocation) {
        setLastKnownLocation(location.pathname);
        if (command && argument) {
          console.log('switched to query!');
          setQuery(JSON.parse(decodeURI(argument)));
          setDeterminedInit(true);
          setShowLoading(true);
        }
        if (!command && !argument) {
          setQueryOutput({});
          setDeterminedInit(true);
        }
      }
    }, [command, argument, location, lastKnownLocation]);
  }

  useEffect(() => {
    if (Object.entries(queryOutput).length > 0) {
      setShowLoading(false);
    }
  }, [queryOutput]);

  useEffect(() => {
    console.log('---QUERY---');
    console.log(query);
    console.log('---END QUERY---');

    if (query && query.length > 0) {
      //let managersRef = firestore.collection('users_old');
      let managersRef = firestore.collection(
        '/users/' + query.filter((i) => i.in)[0].in + '/users'
      );
      managersRef = managersRef.where('role', '==', 'manager');
      // filter by query
      query
        .filter((q) => q.key)
        .forEach((q) => {
          const key = q.key.toLowerCase() + '_lc';
          managersRef = managersRef.where(
            `props.${key}`,
            '==',
            q.value.toLowerCase()
          );
        });

      managersRef.get().then((snapshot) => {
        let newManagers = [];

        // get the snapshot
        snapshot.docs.forEach((doc) => {
          newManagers.push({
            id: doc.id,
            data: doc.data(),
            ratings: [],
          });
        });

        // process each manager
        const getEvaluators = async (manager) => {
          const id = manager.id;
          let relevantToManager = firestore
            .collection('/users/' + query.filter((i) => i.in)[0].in + '/users')
            .where(`relevant_to`, `array-contains`, id);
          const relevants = await relevantToManager.get();
          let toReturn = [];
          relevants.docs.forEach((doc) => {
            toReturn.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          return [manager.id, manager.data, toReturn];
        };

        Promise.all(
          newManagers.map((manager) => {
            return getEvaluators(manager);
          })
        )
          .then((results) => {
            let queryData = {};

            results.forEach((managerEntry) => {
              queryData[managerEntry[0]] = managerEntry;
            });

            setQueryOutput(queryData);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, [query]);

  useEffect(() => {
    console.log('---QUERY OUTPUT---');
    console.log(queryOutput);
    console.log('---END QUERY OUTPUT---');
  }, [queryOutput]);

  return (
    <>
      <Loading loading={showLoading} />
      <Header />
      {determinedInit && (
        <Container>
          {(!queryOutput || Object.keys(queryOutput).length === 0) && (
            <>
              <Message>
                <p>
                  Please select a <i>report</i> to access a live rating summary.
                </p>
              </Message>
              <UniversalQuery setQuery={setQuery} />
              {/*<QueryBrowser />
              <hr />
              <QueryInput
                querySetter={querySetter}
                setQuerySetter={setQuerySetter}
              />*/}
            </>
          )}
          {queryOutput && Object.keys(queryOutput).length > 0 && (
            <>
              {!printMode && (
                <Conditions queryOutput={queryOutput} query={query} />
              )}
              <Output queryOutput={queryOutput} printMode={printMode} setPrintMode={setPrintMode} />
            </>
          )}
        </Container>
      )}
    </>
  );
}
