export function recalculateVault(input) {
	const accountSrc = {
		self: {},
		director: {},
		team: {},
	};
	const questionsSrc = {
		Mindset: [[], [], []],
		Expectations: [[], [], []],
		'Skill and Knowledge Development': [[], [], []],
		'Cultivating Motivation': [[], [], []],
		Feedback: [[], [], []],
		'Coaching Process': [[], [], []],
		'Distance Coaching': [[], [], []],
		Trust: [[], [], []],
	};

	let map = JSON.parse(JSON.stringify(accountSrc)); // copy uppermost
	Object.keys(map).forEach((key) => {
		map[key] = JSON.parse(JSON.stringify(questionsSrc));
	});

	// put values into the map
	input.forEach((value) => {
		const accountMap = {
			manager: 'self',
			director: 'director',
			team_member: 'team',
		};
		const account = accountMap[value.role];

			Object.entries(value.fill).forEach(([key, fill]) => {
				// for each evaluation
				if (value.submitted_ar.includes(key)) {
					// that was completed (present in submitted_ar)
					Object.entries(fill).forEach(([question, ratings]) => {
						for (let i = 0; i < 3; i++) {
							map[account][question][i].push(ratings[i]);
						}
					});
				}
			});
	});

	// average values in the map
	let output = JSON.parse(JSON.stringify(map));

	Object.entries(map).forEach(([account_key, account]) => {
		Object.entries(account).forEach(([question_key, question]) => {
			question.forEach((val, index) => {
				const averaged = parseFloat(val.reduce((a, b) => a + b, 0) / val.length).toFixed(1);
				output[account_key][question_key][index] = averaged;
			})
		})
	})

	return output;
}
