import styles from './Loading.module.scss';

export default function Loading({loading}) {
	return (
		loading && (
			<div className={styles.loading}>
				<img src="/loading.svg" alt="Loading..." />
			</div>
		)
	);
}
