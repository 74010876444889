import styles from "./Eval.module.scss";
import React, { useEffect, useState } from "react";
export default function Eval({ evalKey, data, map, id, last, printMode }) {
  const [relevantDataset, setRelevantDataset] = useState();
  const [formattedDataset, setFormattedDataset] = useState();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (data) {
      let newRelevant = JSON.parse(JSON.stringify(data));
      newRelevant = newRelevant.map((entry) => {
        entry.fill && (entry.fill = entry.fill[evalKey]);
        return entry;
      });
      setRelevantDataset(newRelevant);
    }
  }, [data]);

  useEffect(() => {
    if (relevantDataset) {
      let newFormatted = [];
      // create entry for each question in this eval dataset
      map
        .filter((item) => item[0] === evalKey)[0][1]
        .forEach((key, index) => {
          // TBD - separate results for each of the sub-questions!
          let stripped = relevantDataset.map((entry) => {
            return {
              id: entry.id,
              role: entry.role,
              rating: (entry.fill && entry.fill[index]) || null,
            };
          });
          let scores = {
            all: stripped,
            self: stripped.filter((entry) => {
              return entry.role === "manager" && entry.rating;
            }),
            director: stripped.filter((entry) => {
              return entry.role === "director" && entry.rating;
            }),
            team_members: stripped.filter((entry) => {
              return entry.role === "team_member" && entry.rating;
            }),
          };
          newFormatted.push([index, key, scores]);
        });
      setFormattedDataset(newFormatted);
    }
  }, [relevantDataset]);

  useEffect(() => {
    console.log(formattedDataset);
  }, [formattedDataset]);

  return (
    <div className={[styles.eval, printMode ? styles.print : ""].join(" ")}>
      {formattedDataset && (
        <div
          className={[
            styles.top,
            !expanded && last ? styles.noborder : "",
          ].join(" ")}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <div>{evalKey}</div>
          {[
            (
              formattedDataset
                .map((entry) =>
                  (
                    entry[2]["self"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) / entry[2]["self"].length
                  ).toFixed(1)
                )
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / 3
            ).toFixed(1),
            (
              formattedDataset
                .map((entry) => {
                  return (
                    entry[2]["director"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) / entry[2]["director"].length
                  ).toFixed(1);
                })
                .filter((a) => a !== "0.0")
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              formattedDataset
                .map((entry) => {
                  return (
                    entry[2]["director"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) / entry[2]["director"].length
                  ).toFixed(1);
                })
                .filter((a) => a !== "0.0").length
            ).toFixed(1) +
              (formattedDataset
                .map((entry) => {
                  return (
                    entry[2]["director"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) / entry[2]["director"].length
                  ).toFixed(1);
                })
                .filter((a) => a !== "0.0").length < 3
                ? "WARN"
                : ""),
            "N/A",
            (
              formattedDataset
                .map((entry) =>
                  (
                    entry[2]["team_members"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) /
                    entry[2]["team_members"].length
                  ).toFixed(1)
                )
                .filter((a) => a !== "0.0")
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) /
              formattedDataset
                .map((entry) =>
                  (
                    entry[2]["team_members"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) /
                    entry[2]["team_members"].length
                  ).toFixed(1)
                )
                .filter((a) => a !== "0.0").length
            ).toFixed(1) +
              (formattedDataset
                .map((entry) =>
                  (
                    entry[2]["team_members"]
                      .map((tm) => tm.rating)
                      .reduce((a, b) => a + b, 0) /
                    entry[2]["team_members"].length
                  ).toFixed(1)
                )
                .filter((a) => a !== "0.0").length < 3
                ? "WARN"
                : ""),
            "N/A",
          ].map((entry, index, arr) => {
            let color = null;
            if (index === 2) {
              const dir = arr[index - 1].replace("WARN", "");
              const self = arr[index - 2];
              self !== "0.0" && (entry = ((dir / self) * 100).toFixed(0));
              self !== "0.0" && dir / self <= 0.75 && (color = "red");
              self !== "0.0" && dir / self >= 1.25 && (color = "green");
            }
            if (index === 4) {
              const team = arr[index - 1].replace("WARN", "");
              const self = arr[index - 4];
              self !== "0.0" && (entry = ((team / self) * 100).toFixed(0));
              self !== "0.0" && team / self <= 0.75 && (color = "red");
              self !== "0.0" && team / self >= 1.25 && (color = "green");
            }
            index === 0 && entry === "0.0" && (entry = "N/A");
            let warn = entry.indexOf("WARN") !== -1;
            return (
              <div
                key={index}
                className={[
                  [2, 4].includes(index) ? styles.sep : "",
                  color ? color : "",
                  [0, 3, 4].includes(index) ? styles.accent : "",
                ].join(" ")}
              >
                {entry.replace("WARN", "")}{" "}
                {warn && <img src="/warning.svg" alt="" />}
              </div>
            );
          })}
          <div style={{ justifyContent: "flex-end" }} className={styles.accent}>
            {!expanded && !printMode && (
              <img src="/expand_dark.svg" alt="" className={styles.expand} />
            )}
            {expanded && !printMode && (
              <img
                src="/collapse_dark.svg"
                alt=""
                className={styles.collapse}
              />
            )}
          </div>
        </div>
      )}
      {formattedDataset &&
        expanded &&
        formattedDataset.map((entry, index) => {
          const [director, self, team_members] = [
            (
              entry[2]["director"]
                .map((entry) => entry.rating)
                .reduce((a, b) => a + b, 0) / entry[2]["director"].length
            ).toFixed(1),
            (
              entry[2]["self"]
                .map((entry) => entry.rating)
                .reduce((a, b) => a + b, 0) / entry[2]["self"].length
            ).toFixed(1),
            (
              entry[2]["team_members"]
                .map((entry) => entry.rating)
                .reduce((a, b) => a + b, 0) / entry[2]["team_members"].length
            ).toFixed(1),
          ];
          return (
            <div key={index} className={styles.extended}>
              <div>{entry[1]}</div>
              <div className={styles.accent}>
                {self !== "0.0" ? self : "N/A"}
              </div>
              <div>{director !== "0.0" ? director : "N/A"}</div>
              <div
                className={[
                  styles.sep,
                  self !== "0.0" &&
                  director !== "0.0" &&
                  director / self <= 0.75
                    ? styles.red
                    : "",
                  self !== "0.0" &&
                  director !== "0.0" &&
                  director / self >= 1.25
                    ? styles.green
                    : "",
                ].join(" ")}
              >
                {self !== "0.0" && director !== "0.0"
                  ? ((director / self) * 100).toFixed(0)
                  : "N/A"}
              </div>
              <div className={styles.accent}>
                {team_members !== "0.0" ? team_members : "N/A"}
              </div>
              <div
                className={[
                  styles.sep,
                  styles.accent,
                  self !== "0.0" &&
                  team_members !== "0.0" &&
                  team_members / self <= 0.75
                    ? styles.red
                    : "",
                  self !== "0.0" &&
                  team_members !== "0.0" &&
                  team_members / self >= 1.25
                    ? styles.green
                    : "",
                ].join(" ")}
              >
                {self !== "0.0" && team_members !== "0.0"
                  ? ((team_members / self) * 100).toFixed(0)
                  : "N/A"}
              </div>
              <div className={styles.accent}></div>
            </div>
          );
        })}
    </div>
  );
}
