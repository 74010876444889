//import './Login.scss';
import styles from './Login.module.scss';

import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import {useHistory} from 'react-router-dom';

import Container from '../container/Container';

export default function Login() {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const {currentUser, currentUserData, login} = useAuth(); // signup from context AuthContext
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [wrongCreds, setWrongCreds] = useState(false);
  const [redirectionHandled, setRedirectionHandled] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await login(usernameRef.current.value, passwordRef.current.value);
    } catch (error) {
      console.log(error);
      setWrongCreds(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (typeof currentUser !== 'undefined' && currentUser === null) {
      setRedirectionHandled(true);
    }
    if (currentUser && currentUserData) {
      if (currentUser !== null) {
        // should be moved to auth context?
        switch (currentUserData.role) {
          case 'admin':
            history.push('/admin');
            break;
          default:
            history.push('/assessment');
        }
      }
    }
  }, [currentUser, currentUserData, history]);

  return (
    <>
      {redirectionHandled && (
        <>
          <Container />
          <div className={styles.loginBox}>
            <div>
              <form onSubmit={handleSubmit}>
                <img src="/logo.svg" alt="Romar 360" className={styles.logo} />
                <h3>E-mail</h3>
                <input
                  ref={usernameRef}
                  type="text"
                  placeholder="Please input your e-mail (username)"
                  value={credentials.username}
                  disabled={loading}
                  className={wrongCreds ? styles.wrongCreds : ''}
                  onChange={() => {
                    setWrongCreds(false);
                    setCredentials({
                      username: usernameRef.current.value,
                      password: passwordRef.current.value,
                    });
                  }}
                />
                <h3>Password</h3>
                <input
                  ref={passwordRef}
                  type="password"
                  placeholder="Please input your password"
                  value={credentials.password}
                  disabled={loading}
                  className={wrongCreds ? styles.wrongCreds : ''}
                  onChange={() => {
                    setWrongCreds(false);
                    setCredentials({
                      username: usernameRef.current.value,
                      password: passwordRef.current.value,
                    });
                  }}
                />
                {wrongCreds && (
                  <p className={styles.incorrect}>
                    You have entered incorrect credentials.
                  </p>
                )}
                <hr />
                <button
                  type="submit"
                  className={loading ? styles.loading : ''}
                  disabled={loading}>
                  Continue
                  <img
                    src="/loading-bright.svg"
                    alt=""
                    className={styles.loading}
                  />
                </button>
                <p className={styles.forgotten}>
                  <a href="./resetStart">Forgotten password?</a>
                </p>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
