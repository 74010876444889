import { useAuth } from "../../contexts/AuthContext";
import styles from "./Header.module.scss";
import { useLocation } from "react-router-dom";
import Navigator from "./navigator/Navigator";
import React, { useEffect } from "react";

export default function Header() {
  const { currentUser, currentUserData, logout, switchEval, canonicals } =
    useAuth();
  const location = useLocation();

  const allowPrinting = () => {
    const forManager =
      currentUserData &&
      currentUserData.role === "manager" &&
      location.pathname === "/dashboard";
    return forManager;
  };

  return (
    <div className={[styles.header, "noprint"].join(" ")}>
      <div>
        <div>
          <img src="/logo.svg" alt="Romar" />
          <Navigator />
        </div>
        <div className="noprint">
          {currentUser !== null &&
            currentUserData &&
            currentUserData.role !== "admin" &&
            canonicals.canonicals.length > 1 && (
              <span
                className={styles.print}
                onClick={() => {
                  switchEval();
                }}
              >
                <img src="/switch.svg" alt="" />
                Switch evaluation
              </span>
            )}
          {currentUser !== null && allowPrinting() && (
            <span
              className={styles.print}
              onClick={() => {
                try {
                  document.execCommand("print", false, null);
                } catch (e) {
                  window.print();
                }
              }}
            >
              <img src="/print.svg" alt="" />
              Print
            </span>
          )}
          {currentUser !== null && (
            <span
              className={styles.logout}
              onClick={() => {
                logout();
              }}
            >
              Log out
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
