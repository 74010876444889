import styles from './CompTable.module.scss';

export default function compTable({comp}) {
    
	function returnIndex(val) {
		if (!comp.includes('N/A')) {
			return ((val / comp[0]) * 100).toFixed(0);
		} else {
			return 'N/A';
		}
	}
  function parse(value) {
    if (value !== 'N/A' && !isNaN(value)) return value;
    return 'N/A';
  }

	return (
		<div className={styles.compTable}>
			{[0, 1, 2].map((q, index) => {
				const val = returnIndex(comp[q]);
				return (
					<div
						key={index}
						className={[
							q === 1 ? styles.sep : '',
							val >= 125 ? styles.green : '',
							val <= 75 ? styles.red : '',
						].join(' ')}>
						{parse(val)}
                        
					</div>
				);
			})}
		</div>
	);
}
