import styles from './tButton.module.scss';
import {useHistory} from 'react-router-dom';

export default function TButton({caption, icon, url, callback, loading}) {
	const history = useHistory();

	return (
		<div
			className={[styles.tButton, loading ? styles.loading : ''].join(' ')}
			onClick={() => {
				if (url) {
					history.push(url);
				}
				if (callback) {
					callback();
				}
			}}>
			<img className={styles.loader} src="./loading.svg" alt="" />
			<div>
				<img src={icon} alt="" />
				<hr />
				{caption}
			</div>
		</div>
	);
}
