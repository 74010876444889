import React, { useContext, useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const history = useHistory();
  const [currentUserData, setCurrentUserData] = useState();

  const [canonicals, setCanonicals] = useState({ id: null, canonicals: [] });
  const [canonicalChosen, setCanonicalChosen] = useState(false);
  const [currentCanonical, setCurrentCanonical] = useState(null);
  const [evalTriggered, setEvalTriggered] = useState(false);
  const [evalLoading, setEvalLoading] = useState(false);

  async function login(username, password) {
    await auth.signInWithEmailAndPassword(username, password);
  }

  async function logout() {
    await auth.signOut();
  }

  async function obtainCanonicals(id) {
    const freshCanonicals = (
      await firestore
        .collection("users")
        .where("canonical", "array-contains", id)
        .get()
    ).docs.map((c) => {
      return [c.id, JSON.stringify(c.data())];
    });
    setCanonicals({
      id: id,
      canonicals: freshCanonicals,
    });
  }

  async function reinitializeCanonical(canonical) {
    setEvalLoading(true);
    await obtainUserData(currentUser.email, canonical);
    setTimeout(() => {
      setCanonicalChosen(true);
      setEvalLoading(false);
    }, 1000);
    console.log("Reinitialized AUTH with a different canonical!");
    setCurrentCanonical(canonical);
    history.push(
      "/redirect/" +
        encodeURI(JSON.stringify({ url: history.location.pathname }))
    );
  }

  async function obtainUserData(id, canonical) {
    const userRef = firestore.doc(`/users/${canonical}/users/${id}`);

    const userData = Object.assign((await userRef.get()).data(), {
      canonical,
      userRef,
    });

    setCurrentUserData(userData);
  }

  function clearUserData() {
    setCurrentUserData();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const location = history.location.pathname.split("/")[1];
    const dontRedirect = ["newTeam", "resetStart", "setPassword"];
    if (!dontRedirect.includes(location)) {
      if (currentUser === null) {
        clearUserData();
        history.push("/login");
      } else if (typeof currentUser !== "undefined") {
        obtainCanonicals(currentUser.email);
      }
    }
  }, [currentUser, history]);

  useEffect(() => {
    if (!currentUserData && canonicals.id) {
      console.log(canonicals);
      console.log("No user data available yet, setting first canonical found!");
      setCurrentCanonical(canonicals.canonicals[0][0]);
      obtainUserData(canonicals.id, canonicals.canonicals[0][0]);
    }
  }, [canonicals, currentUserData]);

  useEffect(() => {
    if (currentUserData) {
      console.log("Current user data has changed", currentUserData);
    }
  }, [currentUserData]);

  useEffect(() => {
    console.log("Current canonical has changed", currentCanonical);
  }, [currentCanonical]);

  function switchEval() {
    setEvalTriggered(true);
    setCanonicalChosen(false);
  }

  const value = {
    currentUser,
    currentUserData,
    canonicals,
    canonicalChosen,
    setCanonicalChosen,
    login,
    logout,
    reinitializeCanonical,
    switchEval,
    evalTriggered,
    evalLoading,
    currentCanonical,
    setCurrentCanonical,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
