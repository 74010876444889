import styles from './VaultTable.module.scss';

export default function VaultTable({values}) {
	function returnIndex(vault, user) {
		if (![vault, user].includes('N/A')) {
			return ((user / vault) * 100).toFixed(0);
		} else {
			return 'N/A';
		}
	}
  function parse(value) {
    if (value !== 'N/A' && !isNaN(value)) return value;
    return 'N/A';
  }

	return (
		<div className={styles.vaultTable}>
			{[0, 1, 2].map((q, index) => {
				const val = returnIndex(values.vault[q], values.user[q]);
				return (
					<div
						key={index}
						className={[
							q === 1 ? styles.sep : '',
							val >= 125 ? styles.green : '',
							val <= 75 ? styles.red : '',
						].join(' ')}>
						{parse(val)}
					</div>
				);
			})}
		</div>
	);
}
