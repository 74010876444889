import {functions} from './../../firebase';
import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import styles from './Reset.module.scss';

import Container from '../container/Container';

export default function SetPassword() {
  const history = useHistory();
  const [credentials, setCredentials] = useState({email: null, key: null});
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [wrongMessage, setWrongMessage] = useState('Something went wrong...');
  const [success, setSuccess] = useState(false);

  const setNewPassword = functions.httpsCallable('setNewPassword');

  useEffect(() => {
    const [, , email, key] = history.location.pathname.split('/');
    setCredentials({
      email: email,
      key: key,
    });
  }, [history]);

  useEffect(() => {
    if (password.length > 0 && password.length < 8) {
      setWrongPass(true);
      setWrongMessage('Password needs to be at least 8 characters long.');
    } else {
      setWrongPass(false);
    }
  }, [password]);

  return (
    <Container>
      {success === false && (
        <div className={styles.resetBox}>
          <div>
            <h2>Set up your new password</h2>
            <h3>
              Please enter the new password you want to use to log into the
              Romar 360 system.
            </h3>
            <input
              type="password"
              placeholder="Please input your new password"
              value={password}
              disabled={loading}
              className={wrongPass ? styles.wrongCreds : ''}
              onChange={(e) => {
                setPassword(e.target.value.replace(/ /g, ''));
              }}
            />
            {wrongPass && <p className={styles.incorrect}>{wrongMessage}</p>}
            <hr />
            <button
              disabled={loading || password.length < 8}
              className={loading ? styles.loading : ''}
              onClick={() => {
                setLoading(true);
                setNewPassword({
                  credentials: credentials,
                  password: password,
                }).then((result) => {
                  console.log(result);
                  setLoading(false);
                  if (result.data.success === true) {
                    setSuccess(true);
                  } else {
                    setWrongMessage(result.data.error);
                    setWrongPass(true);
                  }
                });
              }}>
              Continue
              <img
                src="/loading-bright.svg"
                alt=""
                className={styles.loading}
              />
            </button>
          </div>
        </div>
      )}
      {success === true && (
        <div className={styles.resetBox}>
          <div>
            <h2>Success!</h2>
            <h3>You can now sign in using your new password.</h3>
            <hr />
            <button
              onClick={() => {
                history.push('/login');
              }}>
              Sign in
            </button>
          </div>
        </div>
      )}
    </Container>
  );
}
