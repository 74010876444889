import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';

export default function Redirector() {
  const history = useHistory();

  useEffect(() => {
    const newPath = JSON.parse(
      history.location.pathname.replace('/redirect/', '')
    ).url;
    history.push(newPath);
  }, [history]);

  return <div></div>;
}
