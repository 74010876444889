import styles from './ManageTeams.module.scss';
import Header from './../header/Header';
import Container from './../container/Container';
import Message from './../message/Message';
import Popup from './../popup/Popup';
import {firestore, functions} from '../../firebase';

import {useState, useEffect} from 'react';

export default function ManageTeams() {
  const [teams, setTeams] = useState([]);
  const [expanded, setExpanded] = useState('');

  const [deleting, setDeleting] = useState('');
  const [deletionProgress, setDeletionProgress] = useState(-1);
  const [deletionText, setDeletionText] = useState('');

  const [merging, setMerging] = useState('');
  const [mergingTarget, setMergingTarget] = useState('');
  const [mergingTargets, setMergingTargets] = useState([]);
  const [mergeOutput, setMergeOutput] = useState({});
  const [mergeProgress, setMergeProgress] = useState(-1);
  const [mergeText, setMergeText] = useState('');

  const removeOrphanedAuth = functions.httpsCallable('removeOrphanedAuth');
  const createTeamFromMerge = functions.httpsCallable('createTeamFromMerge');

  async function pullUsersData() {
    Promise.all(
      (await firestore.collection('users').get()).docs.map(async (d) => [
        d.id,
        d.data(),
        (await firestore.collection(`users/${d.id}/users`).get()).docs.map(
          (x) => [x.id, x.data()]
        ),
      ])
    ).then((results) => {
      console.log(results);
      setTeams(results);
    });
  }

  useEffect(() => {
    if (
      merging.trim() !== '' &&
      mergingTarget.trim() !== '' &&
      teams.length > 0
    ) {
      const source = teams.filter((t) => t[0] === merging)[0];
      const target = teams.filter((t) => t[0] === mergingTarget)[0];

      const date = new Date();
      const collection =
        source[1]['company_name'] +
        ' ' +
        (date.getUTCMonth() + 1) +
        '-' +
        date.getUTCFullYear() +
        ' [' +
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5) +
        ']';

      // get users from both, sans directors
      let users = [...source[2], ...target[2]].filter(
        (u) => u[1].role !== 'director'
      );

      // sort users
      users = users.sort((a, b) => a[1].name.localeCompare(b[1].name));

      // set up the new combined director
      const sourceDirector = source[2].filter(
        (u) => u[1].role === 'director'
      )[0];
      const sourceManagers = source[2].filter((u) => u[1].role === 'manager');
      const targetDirector = target[2].filter(
        (u) => u[1].role === 'director'
      )[0];
      const targetManagers = target[2].filter((u) => u[1].role === 'manager');

      // compile fill attribute for new director
      const directorFills = [
        ...Object.entries(sourceDirector[1].fill || {}),
        ...Object.entries(targetDirector[1].fill || {}),
      ];
      let directorFill = {};
      directorFills.forEach((v) => {
        directorFill[v[0]] = v[1];
      });

      // compile evaluates multiple for new director
      const fromSource = sourceDirector[1].evaluates_multiple || [
        sourceDirector[1].evaluates,
      ];
      const fromTarget = targetDirector[1].evaluates_multiple || [
        targetDirector[1].evaluates,
      ];

      let newDirector = [
        sourceDirector[0],
        {
          bucket: collection,
          name: sourceDirector[1].name,
          props: sourceDirector[1].props,
          role: 'director',
          fill: directorFill,
          evaluation: sourceDirector[1].evaluation,
          evaluates: sourceDirector[1].evaluates,
          relevant_to: [
            ...sourceManagers.map((m) => m[0]),
            ...targetManagers.map((m) => m[0]),
          ],
          submitted_ar: [
            ...(sourceDirector[1].submitted_ar || []),
            ...(targetDirector[1].submitted_ar || []),
          ],
          evaluates_multiple: [...fromSource, ...fromTarget],
        },
      ];

      // update evaluates_multiple for director
      newDirector[1].evaluates_multiple = newDirector[1].evaluates_multiple.map(
        (e) => {
          return firestore.doc('users/' + collection + '/users/' + e.id);
        }
      );

      users.push(newDirector);

      // update evaluates to make use of the new collection instead
      users.map((u) => {
        let val = u[1];
        val.evaluates = firestore.doc(
          'users/' + collection + '/users/' + val.evaluates.id
        );
        return [u[0], val];
      });

      // parse the references into paths, since we can't send references via onCall functions
      users.map((u) => {
        let val = u[1];
        console.log(val);
        val.evaluates = val.evaluates.path;
        val.evaluation = val.evaluation.path;
        if (val.evaluates_multiple) {
          val.evaluates_multiple = val.evaluates_multiple.map((e) => e.path);
        }
        return [u[0], val];
      });

      // create merge output for further processing
      setMergeOutput({
        collection: collection,
        direct: {
          company_name: source[1]['company_name'],
          managers: users
            .filter((u) => u[1].role === 'manager')
            .map((u) => u[1].name),
          canonical: [...new Set(users.map((u) => u[0]))],
        },
        users: users,
      });
    }
  }, [merging, mergingTarget, teams]);

  useEffect(() => {
    console.log('mo', mergeOutput);
  }, [mergeOutput]);

  useEffect(() => {
    pullUsersData();
  }, []);

  return (
    <>
      {Object.keys(mergeOutput).length > 0 && mergeProgress === -1 && (
        <Popup displayed={merging.trim() !== ''} limit={800}>
          <div className={styles.popup}>
            <h2>Merge collections</h2>
            <h3>Choose the teams to perform the merge operation on.</h3>
            <div className={styles.merging}>
              <select className={styles.noninteractive}>
                <option>{merging}</option>
              </select>
              <img src="/add.svg" alt="" />
              <select
                value={mergingTarget}
                onChange={(e) => {
                  setMergingTarget(e.target.value);
                }}>
                {mergingTargets.map((t, index) => {
                  return <option key={index}>{t}</option>;
                })}
              </select>
            </div>
            <hr />
            <h3>
              This will create a new collection{' '}
              <strong>{mergeOutput.collection}</strong> for company{' '}
              <strong>{mergeOutput.direct.company_name}</strong>.
            </h3>
            {Object.keys(mergeOutput).length > 0 && (
              <div className={styles.newTeamListing}>
                <div>
                  <h4>DIRECTOR:</h4>
                  <ul>
                    {mergeOutput.users
                      .filter((u) => u[1].role === 'director')
                      .map((u, index) => {
                        return (
                          <li key={index}>
                            {u[1].name}
                            <p>{u[0]}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div>
                  <h4>MANAGERS:</h4>
                  <ul>
                    {mergeOutput.users
                      .filter((u) => u[1].role === 'manager')
                      .map((u, index) => {
                        return (
                          <li key={index}>
                            {u[1].name}
                            <p>{u[0]}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div>
                  <h4>TEAM:</h4>
                  <ul>
                    {mergeOutput.users
                      .filter((u) => u[1].role === 'team_member')
                      .map((u, index) => {
                        return (
                          <li key={index}>
                            {u[1].name}
                            <p>{u[0]}</p>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            )}
            <hr />
            <button
              onClick={() => {
                setMerging('');
              }}>
              Cancel
            </button>
            <button
              onClick={async () => {
                setMergeProgress(1);
                setMergeText('Creating a new collection from merge...');

                await createTeamFromMerge({
                  input: JSON.stringify(mergeOutput),
                });

                setMergeText('Cleaning up source collection data...');
                const docs_src = (
                  await firestore.collection(`/users/${merging}/users`).get()
                ).docs.map((e) => e.id);

                await Promise.all(
                  docs_src.map(async (d) => {
                    try {
                      await firestore
                        .doc(`/users/${merging}/users/${d}`)
                        .delete();
                      return true;
                    } catch (e) {
                      console.log(e);
                    }
                  })
                );

                setMergeText('Removing the source collection...');
                await firestore.doc(`/users/${merging}`).delete();

                setMergeText('Cleaning up target collection data...');
                const docs_tar = (
                  await firestore
                    .collection(`/users/${mergingTarget}/users`)
                    .get()
                ).docs.map((e) => e.id);

                await Promise.all(
                  docs_tar.map(async (d) => {
                    try {
                      await firestore
                        .doc(`/users/${mergingTarget}/users/${d}`)
                        .delete();
                      return true;
                    } catch (e) {
                      console.log(e);
                    }
                  })
                );

                setMergeText('Removing the target collection...');
                await firestore.doc(`/users/${mergingTarget}`).delete();

                setMergeText('Removing orphaned data...');
                await removeOrphanedAuth({});

                setMergeText('Done!');

                setMergeProgress(2);
              }}>
              Proceed
            </button>
          </div>
        </Popup>
      )}
      {Object.keys(mergeOutput).length > 0 && mergeProgress !== -1 && (
        <Popup displayed={merging.trim() !== ''} limit={600}>
          <div className={styles.popup}>
            {mergeProgress !== 2 && (
              <>
                <h2>Merge in progress</h2>
                <h3>{mergeText}</h3>
                <p>Please wait...</p>
              </>
            )}
            {mergeProgress === 2 && (
              <>
                <h2>Merge successful</h2>
                <h3>
                  Selected collections were merged into a multi-manager team.
                </h3>
                <hr />
                <button
                  style={{width: '100%'}}
                  onClick={() => {
                    window.location.reload();
                  }}>
                  Continue
                </button>
              </>
            )}
          </div>
        </Popup>
      )}
      <Popup displayed={deleting.trim() !== ''} limit={600}>
        <div className={styles.popup}>
          {deletionProgress === -1 && (
            <>
              <h2>Are you sure?</h2>
              <h3>
                You're about to remove the collection <b>{deleting}</b> from the
                database.
              </h3>
              <p>
                This will permanently remove the evaluation data associated with
                this collection.
              </p>
              <hr />
              <button
                onClick={async () => {
                  setDeleting('');
                }}>
                Cancel
              </button>
              <button
                onClick={async () => {
                  if (window.confirm('Are you sure?')) {
                    setDeletionProgress(1);
                    setDeletionText(
                      'Removing the associated user documents...'
                    );

                    const docs = (
                      await firestore
                        .collection(`/users/${deleting}/users`)
                        .get()
                    ).docs.map((e) => e.id);

                    await Promise.all(
                      docs.map(async (d) => {
                        try {
                          await firestore
                            .doc(`/users/${deleting}/users/${d}`)
                            .delete();
                        } catch (e) {
                          console.log(e);
                        }
                      })
                    );

                    setDeletionText('Removing the collection...');
                    await firestore.doc(`/users/${deleting}`).delete();

                    setDeletionText('Cleaning up the orphaned data...');

                    await removeOrphanedAuth({});

                    setDeletionText('Done!');

                    setDeletionProgress(2);
                  } else {
                    setDeleting('');
                  }
                }}>
                Proceed
              </button>
            </>
          )}
          {deletionProgress === 1 && (
            <>
              <h2>Deletion in progress</h2>
              <h3>{deletionText}</h3>
              <p>Please wait...</p>
            </>
          )}
          {deletionProgress === 2 && (
            <>
              <h2>Deletion complete</h2>
              <h3>
                Collection <b>{deleting}</b> have been removed.
              </h3>
              <hr />
              <button
                style={{width: '100%'}}
                onClick={() => {
                  window.location.reload();
                }}>
                Continue
              </button>
            </>
          )}
        </div>
      </Popup>
      <Header />
      <Container>
        <Message>
          <p>
            In this section you can investigate and manage teams in the
            database.
          </p>
        </Message>
        <div className={styles.management}>
          <h3>
            <img src="/utils.svg" alt="" /> Manage teams
          </h3>
          {teams
            .filter((t) => t[0] !== 'Administrators')
            .sort((a, b) => b[1].company_name.localeCompare(a[1].company_name))
            .sort((a, b) => a[1].managers.length < b[1].managers.length)
            .map((t, index) => {
              const director = t[2].filter(
                (c) => c[1].role === 'director'
              )[0][0];
              const instances = teams.filter(
                (c) =>
                  c[0] !== t[0] &&
                  c[2].filter(
                    (x) => x[0] === director && x[1].role === 'director'
                  ).length > 0
              );
              const canMerge = instances.length > 0;
              return (
                <div key={index} className={styles.entry}>
                  <div>
                    {t[1]['managers'].length === 1 && (
                      <img src="./singlereport.svg" alt="" />
                    )}
                    {t[1]['managers'].length > 1 && (
                      <img src="./multireport.svg" alt="" />
                    )}
                  </div>
                  <div>
                    {t[1]['managers'].length > 1 && (
                      <>
                        <h4>{t[1]['company_name']}</h4>
                        {t[1]['managers'].length <= 3 && (
                          <p>{t[1]['managers'].join(', ')}</p>
                        )}
                        {t[1]['managers'].length > 3 && (
                          <p>
                            {t[1]['managers']
                              .filter((c, i) => i < 3)
                              .join(', ')}{' '}
                            + {t[1]['managers'].length - 3} managers
                          </p>
                        )}
                      </>
                    )}
                    {t[1]['managers'].length === 1 && (
                      <>
                        <h4>{t[1]['managers'][0]}</h4>
                        <p>{t[1]['company_name']}</p>
                      </>
                    )}
                  </div>
                  <div>
                    {expanded !== t[0] && (
                      <button
                        onClick={() => {
                          setExpanded(t[0]);
                        }}>
                        MORE
                        <img src="/expand_dark.svg" alt="" />
                      </button>
                    )}
                    {expanded === t[0] && (
                      <button
                        onClick={() => {
                          setExpanded('');
                        }}>
                        LESS
                        <img src="/collapse_dark.svg" alt="" />
                      </button>
                    )}
                    <button
                      disabled={!canMerge}
                      onClick={() => {
                        setMerging(t[0]);
                        setMergingTarget(instances.map((x) => x[0])[0]);
                        setMergingTargets(instances.map((x) => x[0]));
                      }}>
                      <img src="/merge.svg" alt="" />
                      MERGE
                    </button>
                    <button
                      onClick={() => {
                        setDeleting(t[0]);
                      }}>
                      <img src="/delete.svg" alt="" />
                    </button>
                  </div>
                  {expanded === t[0] && (
                    <>
                      <div></div>
                      <div className={styles.data}>
                        <div>
                          <h4>Director:</h4>
                          <ul>
                            {t[2]
                              .filter((c) => c[1].role === 'director')
                              .map((c, index) => {
                                return (
                                  <li key={index}>
                                    {c[1].name}
                                    <p>{c[0]}</p>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div>
                          {t[2].filter((c) => c[1].role === 'manager')
                            .length === 1 && <h4>Manager:</h4>}
                          {t[2].filter((c) => c[1].role === 'manager').length >
                            1 && <h4>Managers:</h4>}
                          <ul>
                            {t[2]
                              .filter((c) => c[1].role === 'manager')
                              .map((c, index) => {
                                return (
                                  <li key={index}>
                                    {c[1].name}
                                    <p>{c[0]}</p>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div>
                          <h4>Team:</h4>
                          <ul>
                            {t[2]
                              .filter((c) => c[1].role === 'team_member')
                              .map((c, index) => {
                                return (
                                  <li key={index}>
                                    {c[1].name}
                                    <p>{c[0]}</p>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div></div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </Container>
    </>
  );
}
