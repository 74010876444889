import styles from "./TeamSelection.module.scss";

import { useAuth } from "../../contexts/AuthContext";

export default function TeamSelection() {
  const {
    currentUser,
    currentUserData,
    canonicals,
    canonicalChosen,
    setCanonicalChosen,
    reinitializeCanonical,
    evalTriggered,
    evalLoading,
    currentCanonical,
  } = useAuth();

  return (
    <>
      {currentUserData &&
        currentUserData["role"] !== "admin" &&
        canonicals.canonicals.length > 1 &&
        !canonicalChosen && (
          <div
            className={[
              styles.container,
              evalTriggered ? styles.triggered : "",
              evalLoading ? styles.loading : "",
            ].join(" ")}
          >
            <div>
              <h2>Please select evaluation:</h2>
              {canonicals.canonicals
                .filter((c) => c[0] !== "Administrators")
                .map((c, index) => {
                  if (c[0] === currentCanonical) {
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          setCanonicalChosen(true);
                        }}
                      >
                        {JSON.parse(c[1]).managers.length === 1 && (
                          <>
                            <img src="./singlereport_white.svg" alt="" />
                            {JSON.parse(c[1]).managers[0]}
                          </>
                        )}
                        {JSON.parse(c[1]).managers.length > 1 && (
                          <>
                            <img src="./multireport_white.svg" alt="" />
                            {JSON.parse(c[1]).company_name}
                          </>
                        )}
                      </button>
                    );
                  } else {
                    return (
                      <button
                        key={index}
                        onClick={() => {
                          console.log(currentUser.email);
                          reinitializeCanonical(c[0]);
                        }}
                      >
                        {JSON.parse(c[1]).managers.length === 1 && (
                          <>
                            <img src="./singlereport_white.svg" alt="" />
                            {JSON.parse(c[1]).managers[0]}
                          </>
                        )}
                        {JSON.parse(c[1]).managers.length > 1 && (
                          <>
                            <img src="./multireport_white.svg" alt="" />
                            {JSON.parse(c[1]).company_name}
                          </>
                        )}
                      </button>
                    );
                  }
                })}
            </div>
          </div>
        )}
    </>
  );
}
