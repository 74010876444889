import React, {useContext, useState, useEffect} from 'react';
import {useAuth} from './AuthContext';
import {firestore} from '../firebase';

const FavoritesContext = React.createContext();

export function useFavorites() {
	return useContext(FavoritesContext);
}

export function FavoritesProvider({children}) {
	const {currentUser} = useAuth();
	const [favorites, setFavorites] = useState(); // initialize as empty, assume empty
	const favoritesRef = firestore.collection('variables').doc('favorites');

	async function snapshotHandler(data) {
		setFavorites(data);
	}

	useEffect(() => {
		if (currentUser !== null) {
			favoritesRef.onSnapshot((favSnapshot) => {
				snapshotHandler(favSnapshot.data()['favorites']);
			});
		}
	}, [currentUser]);

	function queryToIdentifier(input) {
		return encodeURI(
			JSON.stringify(
				input.map((entry) => {
					if (entry.key) {
						return {
							key: entry.key.toLowerCase(),
							value: entry.value.toLowerCase(),
						};
					}
					if (entry.in) {
						return {
							in: entry.in,
						};
					}
					return null;
				})
			)
		);
	}

	function isInFavorites(input) {
		if (favorites) {
			return (
				favorites.filter((fav) => fav.id === queryToIdentifier(input)).length >
				0
			);
		} else {
			return false;
		}
	}

	async function addToFavorites(input, name) {
		if (favorites) {
			const thisFavorite = {
				id: queryToIdentifier(input),
				value: input,
				name: name,
				timestamp: Date.now(),
			};
			console.log('Adding to favorites!', thisFavorite);
			await favoritesRef.update({favorites: favorites.concat(thisFavorite)});
		}
	}

	async function removeFromFavorites(input) {
		if (favorites) {
			await favoritesRef.update({
				favorites: favorites.filter(
					(entry) => entry.id !== queryToIdentifier(input)
				),
			});
		}
	}

	function readFavorites() {
		return favorites || [];
	}

	const value = {
		readFavorites,
		isInFavorites,
		addToFavorites,
		removeFromFavorites,
	};

	return (
		<FavoritesContext.Provider value={value}>
			{children}
		</FavoritesContext.Provider>
	);
}
