import styles from './PerfChart.module.scss';
import React, {useState, useEffect} from 'react';
import RocChart from './rocChart/RocChart';
import VaultTable from './vaultTable/VaultTable';
import CompTable from './compTable/CompTable';

import {useVault} from '../../../contexts/VaultContext';
import {useAuth} from '../../../contexts/AuthContext';

export default function PerfChart({
	relevantData,
	displayCompletionChart = false,
}) {
	const [filter, setFilter] = useState('Mindset');
	const {vaultData, pullVaultData, vaultDataPresent} = useVault();
	const {currentUser} = useAuth();

	const map = [
		[
			'Mindset',
			[
				'This manager prioritizes coaching as being as important as anything else team members do',
				'This manager provides routine coaching to team members',
				'This manager focuses team members’ development not just on weaknesses but more on things they do well',
			],
		],
		[
			'Expectations',
			[
				'This manager communicates a clear understanding of what he/she expects of team members in their role',
				'This manager shares what good performance looks and sounds like for all vital tasks team members perform in their role',
				'This manager routinely discusses performance to expectations with team members',
			],
		],
		[
			'Skill and Knowledge Development',
			[
				'This manager teaches team members new skills, knowledge, and behaviors to help them improve',
				'This manager models a new skill, behavior, or application of new knowledge as a teaching tool for team members',
				'This manager encourages team members to try new skills or behaviors or apply new knowledge without negative consequences',
			],
		],
		[
			'Cultivating Motivation',
			[
				'This manager illustrates the positive impact of learning a new skill, knowledge, or behavior',
				'This manager gives positive encouragement to team members when they try a new skill or behavior or apply new knowledge',
				'This manager knows what motivates team members to try something new or learn a new skill, knowledge, or behavior',
			],
		],
		[
			'Feedback',
			[
				'This manager provides positive feedback when team members do something well',
				'This manager provides constructive feedback when team members do something that could improve',
				'This manager provides clear, concise, and complete feedback',
			],
		],
		[
			'Coaching Process',
			[
				'Team members have a clear coaching objective, which they are working on currently',
				'This manager has established a coaching plan for each team member for the next few months',
				'This manager devotes specific time monthly to each team member’s development',
			],
		],
		[
			'Distance Coaching',
			[
				'This manager connects routinely virtually for coaching-and-feedback sessions with team members',
				'This manager provides appropriate positive feedback virtually when a team member does something well',
				'This manager collaboratively creates coaching plans virtually with input from team members',
			],
		],
		[
			'Trust',
			[
				'This manager knows and understands team members’ roles well enough to provide coaching',
				'This manager is honest and candid with team members',
				'This manager genuinely cares about team members’ development and success',
			],
		],
	];

	function filteredVaultData(question) {
		if (vaultDataPresent && vaultData) {
			return [
				parseFloat(vaultData.vault.self[filter][question]).toFixed(1),
				parseFloat(vaultData.vault.director[filter][question]).toFixed(1),
				parseFloat(vaultData.vault.team[filter][question]).toFixed(1),
			];
		} else {
			return ['N/A', 'N/A', 'N/A'];
		}
	}

	useEffect(() => {
		console.log(relevantData);
	}, [relevantData]);

	useEffect(() => {
		if (currentUser && !vaultDataPresent()) {
			pullVaultData();
		}
	}, [currentUser, pullVaultData, vaultDataPresent]);

	function getQuestionsForVariable(variable) {
		return map.filter((val) => val[0] === variable)[0][1];
	}

	function getAverage(variable, question_index, user_type_filter) {
		let relevant = JSON.parse(JSON.stringify(relevantData)).filter(
			(entry) => entry.role === user_type_filter
		);
		let scores = [];
		relevant.forEach((entry) => {
			if (entry.fill && Object.keys(entry.fill).includes(variable)) {
				scores.push(entry.fill[variable][question_index]);
			}
		});
		if (scores.length > 0)
			return (scores.reduce((a, b) => a + b, 0) / scores.length).toFixed(1);
		return 'N/A';
	}

	function getAverages(question) {
		return [
			getAverage(filter, question, 'manager'),
			getAverage(filter, question, 'director'),
			getAverage(filter, question, 'team_member'),
		];
	}

	function completion() {
    let result = (
			relevantData.filter((f) => 'fill' in f && f.fill !== null).length /
			relevantData.length
		)
    if(isNaN(result)) {
      result = 0;
    }
		return result;
	}

	return (
		<>
			{displayCompletionChart && relevantData && (
				<div className={styles.progressContainer}>
					<div>Completion:</div>
					<div>
						<div className={styles.fill}>
							<div style={{minWidth: "50px", width: completion() * 100 + '%'}}>
								<div>{(completion() * 100).toFixed(0) + '%'}</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className={styles.perfChartContainer}>
				{relevantData && (
					<div className={styles.charts}>
						<div></div>
						{getQuestionsForVariable(filter).map((entry, index) => {
							return <div key={index}>{entry}</div>;
						})}
						<div className={styles.title}>ROCs Ratings</div>
						<div>
							<RocChart values={getAverages(0)} />
						</div>
						<div>
							<RocChart values={getAverages(1)} />
						</div>
						<div>
							<RocChart values={getAverages(2)} />
						</div>
						<div className={styles.title} style={{maxHeight: '50px'}}>
							Comp Index
						</div>
						<div>
							<CompTable comp={getAverages(0)} />
						</div>
						<div>
							<CompTable comp={getAverages(1)} />
						</div>
						<div>
							<CompTable comp={getAverages(2)} />
						</div>
						<div className={styles.title} style={{maxHeight: '50px'}}>
							Vault Index
						</div>
						<div>
							<VaultTable
								values={{vault: filteredVaultData(0), user: getAverages(0)}}
							/>
						</div>
						<div>
							<VaultTable
								values={{vault: filteredVaultData(1), user: getAverages(1)}}
							/>
						</div>
						<div>
							<VaultTable
								values={{vault: filteredVaultData(2), user: getAverages(2)}}
							/>
						</div>
					</div>
				)}
				<div className={styles.nav}>
					Displaying:
					<select
						value={filter}
						onChange={(e) => {
							setFilter(e.target.value);
						}}>
						<option>Mindset</option>
						<option>Expectations</option>
						<option>Skill and Knowledge Development</option>
						<option>Cultivating Motivation</option>
						<option>Feedback</option>
						<option>Coaching Process</option>
						<option>Distance Coaching</option>
						<option>Trust</option>
					</select>
				</div>
			</div>
		</>
	);
}
