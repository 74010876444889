import styles from './Checkbox.module.scss';
export default function Checkbox({submitted, selected}) {
  return (
    <div
      className={[
        styles.checkbox,
        selected ? styles.selected : '',
        submitted ? styles.submitted : '',
      ].join(' ')}>
      {selected}
    </div>
  );
}
