import {AuthProvider} from '../contexts/AuthContext';
import {FavoritesProvider} from '../contexts/FavoritesContext';
import {VaultProvider} from '../contexts/VaultContext';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Login from './login/Login';
import Assessment from './assessment/Assessment';
import Query from './query/Query';
import Admin from './admin/Admin';
import Dashboard from './dashboard/Dashboard';
import Utilitites from './utilities/Utilitites';
import Vault from './vault/Vault';
import NewTeam from './newteam/NewTeam';
import ResetStart from './passwordReset/ResetStart';
import SetPassword from './passwordReset/SetPassword';
import TeamSelection from './teamselection/TeamSelection';
import Redirector from './redirector/Redirector';
import ManageTeams from "./manageteams/ManageTeams";
import Backup from './backup/Backup';

import './App.scss';

export default function App() {
  return (
    <div className="app">
      <Router>
        <AuthProvider>
          <FavoritesProvider>
            <VaultProvider>
              <TeamSelection />
              <Switch>
                <Route path="/query/:command?/:argument?" component={Query} />
                <Route path="/assessment" component={Assessment} />
                <Route path="/admin" component={Admin} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/utilities" component={Utilitites} />
                <Route path="/vault" component={Vault} />
                <Route path="/newTeam" component={NewTeam} />
                <Route path="/resetStart" component={ResetStart} />
                <Route path="/setPassword/:email/:key" component={SetPassword} />
                <Route path="/redirect/:path" component={Redirector} />
                <Route path="/manageTeams" component={ManageTeams} />
                <Route path="/backup" component={Backup} />
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route path="/login" component={Login} />
              </Switch>
            </VaultProvider>
          </FavoritesProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}
