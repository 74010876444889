import React, {useState, useEffect} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import './Assessment.scss';

import Header from '../header/Header';
import Checkbox from './checkbox/Checkbox';
import Popup from '../popup/Popup';
import Message from '../message/Message';
import Container from '../container/Container';
import EvalSubmit from './evalSubmit/EvalSubmit';

import stylesPopup from './PopupEvaluates.module.scss';

export default function Assessment() {
  const {currentUser, currentUserData} = useAuth();

  const [evaluator, setEvaluator] = useState();
  let isEvaluatorSet = false;
  const [evaluates, setEvaluates] = useState();
  let isEvaluatesSet = false;
  const [evaluatesSelection, setEvaluatesSelection] = useState();
  let isEvaluatesSelectionSet = false;

  const [evaluation, setEvaluation] = useState();
  let isEvaluationSet = false;
  const [fill, setFill] = useState({});
  const [completion, setCompletion] = useState();
  const [submittedArray, setSubmittedArray] = useState([]);

  const [expanded, setExpanded] = useState();
  const [manuallyCollapsed, setManuallyCollapsed] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDimissed, setConfirmationDimissed] = useState(true); // start with dismissed temporarily
  const [showEvaluatesInfo, setShowEvaluatesInfo] = useState(false);

  const order = [
    'Mindset',
    'Expectations',
    'Skill and Knowledge Development',
    'Cultivating Motivation',
    'Feedback',
    'Coaching Process',
    'Distance Coaching',
    'Trust',
  ];

  /*const userRef =
		currentUser && currentUser !== null && currentUserData
			? currentUserData.userRef
			: null;*/
  const [userRef, setUserRef] = useState(null);

  useEffect(() => {
    if (completion && expanded) {
      console.log(completion);
      Object.entries(completion.parts).forEach((c) => {
        if (c[1] === true) {
          console.log(c[0] + ' is completed');
          const index = order.indexOf(c[0]);
          const next = order[index + 1];
          if (next) {
            if (
              expanded.indexOf(next) === -1 &&
              manuallyCollapsed.indexOf(next) === -1
            ) {
              setExpanded((prevValue) => {
                prevValue.push(next);
                return prevValue;
              });
            }
          }
        }
      });
    }
  }, [completion, expanded]);

  useEffect(() => {
    console.log(expanded);
  }, [expanded]);

  const snapshotHandler = async (data) => {
    const userData = data;

    console.log(userData);

    !isEvaluatorSet && (isEvaluatorSet = true) && setEvaluator(userData); // set data for evaluator (self) only once

    // set evaluation, but only if no evaluation, only once
    if (!isEvaluationSet) {
      isEvaluationSet = true;
      const evalData = await userData.evaluation.get();
      if (evalData) {
        setEvaluation(evalData.data());
      } else {
        console.log('Could not find referenced evaluation.');
      }
    }

    // EVALUATES
    // pull entirely from the server, this is to be CRITICALLY fixed in the future
    if (!isEvaluatesSet) {
      console.log('Pulling fresh evaluates!');
      //isEvaluatesSet = true;
      const evalData = await userData.evaluates.get();
      if (evalData) {
        setEvaluates({
          data: evalData.data(),
          id: evalData.id,
        });
      } else {
        console.log(
          'Could not find referenced user that would be to be evaluated.'
        );
      }
    } else {
      // if already pulled from the server and evaluatesSelection exist, pull from the repository
      console.log(evaluates);
      console.log('Pulling from repository?');
    }

    // only pull selection for evaluation once to save reads
    if (!isEvaluatesSelectionSet && userData['evaluates_multiple']) {
      console.log('Evaluates multiple users.');
      isEvaluatesSelectionSet = true;
      Promise.all(
        userData['evaluates_multiple'].map(async (entry) => {
          const entryData = await entry.get();
          return {
            id: entryData.id,
            data: entryData.data(),
            ref: entry,
          };
        })
      )
        .then((results) => {
          setEvaluatesSelection(results);
        })
        .catch((error) => {
          console.log('Could not pull multiple users for evaluation.', error);
        });
    }

    // unconditionally update fill
    if (userData.fill) {
      setFill(userData.fill);
    } else {
      setFill({});
      console.log('No evaluation data to fill.');
    }

    // unconditionally update submitted array status
    if (userData.submitted_ar) {
      setSubmittedArray(userData.submitted_ar);
    } else {
      setSubmittedArray([]);
      console.log('No submitted array.');
    }
  };

  const [subscriptionSet, setSubscriptionSet] = useState(false);

  // on start, get data, set up subscription
  /*useEffect(() => {
		if (userRef !== null) {
			const unsubscribe = userRef.onSnapshot((querySnapshot) => {
				snapshotHandler(querySnapshot.data());
			});
			return () => unsubscribe();
		}
	}, []);*/

  useEffect(() => {
    if (subscriptionSet === false && userRef === null) {
      if (currentUser && currentUser !== null && currentUserData) {
        setSubscriptionSet(true);
        setUserRef(currentUserData.userRef);
      }
    }
  }, [currentUser, currentUserData, subscriptionSet, userRef]);

  useEffect(() => {
    if (userRef !== null) {
      const unsubscribe = userRef.onSnapshot((querySnapshot) => {
        snapshotHandler(querySnapshot.data());
      });
      return () => unsubscribe();
    }
  }, [userRef]);

  // when fill changed, calculate completion
  useEffect(() => {
    if (evaluation) {
      let newCompletion = {
        parts: {},
        all: false,
      };
      Object.entries(evaluation.questions).forEach(([key, value]) => {
        const comp =
          (fill[evaluates.id] &&
            fill[evaluates.id][key] &&
            Object.keys(fill[evaluates.id][key]).length) ||
          0;
        newCompletion.parts[key] = value.length === comp;
      });
      newCompletion.all =
        Object.entries(newCompletion.parts).filter(
          ([key, entry]) => entry === true
        ).length === Object.entries(newCompletion.parts).length;
      setCompletion(newCompletion);
    }
  }, [fill]);

  // if evaluation is pulled, but expanded not yet set, expand the first one
  useEffect(() => {
    if ((evaluation, evaluates, completion)) {
      if (!expanded) {
        Object.entries(completion.parts).filter(
          ([key, value]) => value === true
        ).length === 0 && setExpanded([evaluation.order[0]]);

        if (
          Object.entries(completion.parts).filter(
            ([key, value]) => value === true
          ).length > 0
        ) {
          setExpanded(
            Object.entries(completion.parts)
              .filter(([key, value]) => value === true)
              .map(([key, value]) => key)
          );
        }

        completion.all === true && // if all completed, then open all
          setExpanded(evaluation.order);
      }
    }
  }, [evaluation, expanded, evaluates, completion]);

  // listen until all prerequisites have loaded
  useEffect(() => {
    // stop loading if all present
    // TBD

    // show data confirmation request if not confirmed
    evaluator &&
      evaluates &&
      evaluation &&
      !evaluator['confirmed_correct'] &&
      setShowConfirmation(true);
  }, [evaluator, evaluates, evaluation]);

  return (
    <>
      <Header />
      <Popup displayed={showConfirmation} dismissed={confirmationDimissed}>
        {/* this one is not modularized yet, wasn't yet in use */}
        <h3>Welcome!</h3>
        <p>Before you continue, please confirm the information below.</p>
        <div className="table">
          <div>
            <h4>You are:</h4>
            <h2>{evaluator && evaluator['name']}</h2>
            {evaluator && evaluator.props && (
              <ul>
                {Object.entries(evaluator.props).map(([key, value]) => {
                  return (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  );
                })}
              </ul>
            )}
            {evaluator && !evaluator.props && (
              <div>This user has no properties.</div>
            )}
          </div>
          {evaluator && !evaluatesSelection && (
            <div>
              <h4>Evaluating:</h4>
              <h2>{evaluates && evaluates.data['name']}</h2>
              {evaluates && evaluates.data.props && (
                <ul>
                  {Object.entries(evaluates.data.props).map(([key, value]) => {
                    return (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    );
                  })}
                </ul>
              )}
              {evaluates && !evaluates.data.props && (
                <div>This user has no properties.</div>
              )}
            </div>
          )}
          {evaluator && evaluatesSelection && (
            <div>
              <h4>Evaluating ({evaluatesSelection.length}):</h4>
              <ol>
                {evaluatesSelection.map((entry, index) => {
                  return (
                    <li key={index}>
                      <h2>{entry.data.name}</h2>
                      <ul>
                        {!entry.data.props && (
                          <li>This user has no properties.</li>
                        )}
                        {entry.data.props &&
                          Object.entries(entry.data.props).map(
                            ([key, value], index) => {
                              return (
                                <li key={index}>
                                  <strong>{key}:</strong> {value}
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </li>
                  );
                })}
              </ol>
            </div>
          )}
        </div>
        <hr />
        <div className="buttons">
          <button
            className="red"
            onClick={() => {
              alert('This feature is not yet available.');
            }}>
            Report
          </button>
          <button
            onClick={() => {
              setConfirmationDimissed(true);
              setShowConfirmation(false);
            }}>
            Ask me later
          </button>
          <button
            onClick={async () => {
              setShowConfirmation(false);
              await userRef.update({confirmed_correct: true});
            }}>
            Confirm and continue
          </button>
        </div>
      </Popup>
      <Popup displayed={showEvaluatesInfo} limit="500px">
        <div className={stylesPopup.popup}>
          {evaluatesSelection && (
            <>
              <p>Please select which person you want to evaluate:</p>
              <select
                value={
                  evaluatesSelection.filter(
                    (entry) => entry.id === evaluates.id
                  )[0].data.name
                }
                onChange={async (event) => {
                  const value = event.target.value;
                  const newEvaluatesRef = evaluatesSelection.filter(
                    (entry) => entry.data.name === value
                  )[0].ref;
                  await userRef.update({
                    evaluates: newEvaluatesRef,
                  });
                }}>
                {evaluatesSelection.map((entry, index) => {
                  return (
                    <option key={index} value={entry.data.id}>
                      {entry.data.name}
                    </option>
                  );
                })}
              </select>
              <hr />
            </>
          )}
          <div className={stylesPopup.popupTable} style={{margin: '0px'}}>
            <div>
              <h4>Person chosen for evaluation:</h4>
              <h2>{evaluates && evaluates.data.name}</h2>
              {evaluates && evaluates.data.props && (
                <ul>
                  {Object.entries(evaluates.data.props)
                    .filter(([key, value]) => {
                      return key.indexOf('_lc') === -1;
                    })
                    .map(([key, value], index) => {
                      return (
                        <li key={index}>
                          <strong>{key}:</strong> {value}
                        </li>
                      );
                    })}
                </ul>
              )}
              {evaluates && !evaluates.data.props && (
                <ul>
                  <li>This user has no properties.</li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div
          className={stylesPopup.popupButtons}
          style={{gridTemplateColumns: '1fr 2fr'}}>
          <button
            className={stylesPopup.red}
            disabled
            onClick={() => {
              alert('This feature is not yet available.');
            }}>
            Report as incorrect
          </button>
          <button
            onClick={() => {
              setShowEvaluatesInfo(false);
            }}>
            Close
          </button>
        </div>
      </Popup>
      <Container>
        {evaluator && evaluates && submittedArray && (
          <Message>
            <h2>Welcome, {currentUserData && currentUserData['name']}!</h2>
            {evaluator['name'] !== evaluates.data['name'] &&
              !submittedArray.includes(evaluates.id) && (
                <p>
                  You are evaluating{' '}
                  <span
                    className="hoverable"
                    onClick={() => {
                      setShowEvaluatesInfo(true);
                    }}>
                    {evaluates.data['name']}
                  </span>
                  .
                </p>
              )}
            {evaluator['name'] !== evaluates.data['name'] &&
              submittedArray.includes(evaluates.id) && (
                <p>
                  You have evaluated{' '}
                  <span
                    className="hoverable"
                    onClick={() => {
                      setShowEvaluatesInfo(true);
                    }}>
                    {evaluates.data['name']}
                  </span>
                  .
                </p>
              )}
            {evaluator['name'] === evaluates.data['name'] &&
              !submittedArray.includes(evaluates.id) && (
                <p>You are evaluating yourself.</p>
              )}
            {evaluator['name'] === evaluates.data['name'] &&
              submittedArray.includes(evaluates.id) && (
                <p>You have evaluated yourself.</p>
              )}
          </Message>
        )}
        <div>
          {evaluation &&
            evaluates &&
            evaluation.order.map((key, index) => {
              console.log(key);
              const isExpanded = expanded && expanded.includes(key);
              const isCompleted =
                (completion && completion.parts && completion.parts[key]) ||
                false;
              return (
                <div
                  className={[
                    'chapter',
                    isExpanded ? '' : 'collapsed',
                    submittedArray && submittedArray.includes(evaluates.id)
                      ? 'completed'
                      : '',
                  ].join(' ')}
                  key={index}>
                  <div
                    className={['title', isExpanded ? '' : 'collapsed'].join(
                      ' '
                    )}
                    onClick={() => {
                      !expanded.includes(key) &&
                        setExpanded(expanded.concat([key]));
                      if (expanded.includes(key)) {
                        setExpanded(expanded.filter((i) => i !== key));
                        setManuallyCollapsed((prevValue) => {
                          prevValue.push(key);
                          prevValue = [...new Set(prevValue)];
                          return prevValue;
                        });
                      }
                    }}>
                    {!isCompleted && (
                      <img
                        src="/check-empty.svg"
                        alt=""
                        className="incomplete"
                      />
                    )}
                    {isCompleted && (
                      <img src="/check.svg" alt="" className="completed" />
                    )}
                    {key}
                    <img src="/expand.svg" alt="" className="expand" />
                    <img src="/collapse.svg" alt="" className="collapse" />
                  </div>
                  <div
                    className={[
                      'variables',
                      isExpanded ? '' : 'collapsed',
                    ].join(' ')}>
                    <div className="variable">
                      <div></div>
                      <div>Strongly Disagree</div>
                      <div>Somewhat Disagree</div>
                      <div>Undecided</div>
                      <div>Somewhat Agree</div>
                      <div>Strongly Agree</div>
                    </div>
                    {evaluation.questions[key].map((question, index) => {
                      const indices = [1, 2, 3, 4, 5];
                      return (
                        <div className="variable" key={index}>
                          <div>{question}</div>

                          {indices.map((ind) => {
                            let checked;
                            try {
                              checked = fill[evaluates.id][key][index] === ind;
                            } catch (e) {}

                            return (
                              <div
                                key={ind}
                                onClick={async () => {
                                  let newFill = JSON.parse(
                                    JSON.stringify(fill)
                                  ); // make a copy of data from fill
                                  // if none for evaluated yet, create
                                  !Object.keys(newFill).includes(
                                    evaluates.id
                                  ) && (newFill[evaluates.id] = {});
                                  // if none for key (question), create
                                  !Object.keys(newFill[evaluates.id]).includes(
                                    key
                                  ) && (newFill[evaluates.id][key] = {});
                                  // assign the value
                                  newFill[evaluates.id][key][index] = ind;
                                  // set fill state
                                  setFill(newFill);
                                  // update on server
                                  await userRef.update({fill: newFill});
                                }}>
                                {
                                  <Checkbox
                                    submitted={submittedArray.includes(
                                      evaluates.id
                                    )}
                                    selected={checked ? true : false}
                                  />
                                }
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        {evaluation && evaluates && (
          <EvalSubmit
            disabled={completion && !completion.all}
            submitted={submittedArray.includes(evaluates.id)}
            onClickHandler={async () => {
              let newSubmittedArray = JSON.parse(
                JSON.stringify(submittedArray)
              );
              !newSubmittedArray.includes(evaluates.id) &&
                newSubmittedArray.push(evaluates.id);
              await userRef.update({submitted_ar: newSubmittedArray});
            }}
          />
        )}
      </Container>
    </>
  );
}
