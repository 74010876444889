import styles from "./Result.module.scss";
import Eval from "./eval/Eval";
import PerfChart from "../../../dashboard/perfChart/PerfChart";
import { useAuth } from "../../../../contexts/AuthContext";

import React, { useEffect, useState } from "react";

export default function Result({
  index,
  id,
  document,
  triggerPrint,
  printMode,
}) {
  const [relevantDataset, setRelevantDataset] = useState(); // contains only fills for the user
  const map = [
    [
      "Mindset",
      [
        "This manager prioritizes coaching as being as important as anything else team members do",
        "This manager provides routine coaching to team members",
        "This manager focuses team members’ development not just on weaknesses but more on things they do well",
      ],
    ],
    [
      "Expectations",
      [
        "This manager communicates a clear understanding of what he/she expects of team members in their role",
        "This manager shares what good performance looks and sounds like for all vital tasks team members perform in their role",
        "This manager routinely discusses performance to expectations with team members",
      ],
    ],
    [
      "Skill and Knowledge Development",
      [
        "This manager teaches team members new skills, knowledge, and behaviors to help them improve",
        "This manager models a new skill, behavior, or application of new knowledge as a teaching tool for team members",
        "This manager encourages team members to try new skills or behaviors or apply new knowledge without negative consequences",
      ],
    ],
    [
      "Cultivating Motivation",
      [
        "This manager illustrates the positive impact of learning a new skill, knowledge, or behavior",
        "This manager gives positive encouragement to team members when they try a new skill or behavior or apply new knowledge",
        "This manager knows what motivates team members to try something new or learn a new skill, knowledge, or behavior",
      ],
    ],
    [
      "Feedback",
      [
        "This manager provides positive feedback when team members do something well",
        "This manager provides constructive feedback when team members do something that could improve",
        "This manager provides clear, concise, and complete feedback",
      ],
    ],
    [
      "Coaching Process",
      [
        "Team members have a clear coaching objective, which they are working on currently",
        "This manager has established a coaching plan for each team member for the next few months",
        "This manager devotes specific time monthly to each team member’s development",
      ],
    ],
    [
      "Distance Coaching",
      [
        "This manager connects routinely virtually for coaching-and-feedback sessions with team members",
        "This manager provides appropriate positive feedback virtually when a team member does something well",
        "This manager collaboratively creates coaching plans virtually with input from team members",
      ],
    ],
    [
      "Trust",
      [
        "This manager knows and understands team members’ roles well enough to provide coaching",
        "This manager is honest and candid with team members",
        "This manager genuinely cares about team members’ development and success",
      ],
    ],
  ];
  const [completion, setCompletion] = useState(0);
  const [minimized, setMinimized] = useState(true);
  const [showPerfChart, setShowPerfChart] = useState(
    index === 1 ? true : false
  );
  const { currentUser, currentUserData } = useAuth();

  useEffect(() => {
    // naive code for maintenance
    const evalSrc = document[2];
    let newRelevant = [];
    evalSrc.forEach((entry) => {
      newRelevant.push({
        id: entry.id,
        role: entry.data.role,
        fill:
          entry.data.fill && entry.data.fill[id] ? entry.data.fill[id] : null,
      });
    });
    setCompletion(
      (evalSrc.filter((entry) => entry.data.fill && entry.data.fill[id])
        .length /
        evalSrc.length) *
        100
    );
    setRelevantDataset(newRelevant);
  }, [document]);

  function calculateAveragedSummarySelf(dataset) {
    if (
      dataset.length > 0 &&
      dataset.filter((entry) => {
        return entry.id === id;
      })[0].fill // check if self has fill
    ) {
      return (
        Object.entries(
          dataset.filter((entry) => {
            return entry.id === id;
          })[0].fill
        )
          .map(([key, value]) => {
            return Object.values(value).reduce((a, b) => a + b, 0) / 3;
          })
          .reduce((a, b) => a + b, 0) / map.length
      ).toFixed(1);
    } else {
      return "N/A";
    }
  }

  function calculateAveragedSummaryDirector(dataset) {
    if (
      dataset.length > 0 &&
      dataset.filter((entry) => {
        return entry.role === "director";
      })[0].fill
    ) {
      let answers = [
        ...[
          ...Object.entries(
            dataset.filter((entry) => {
              return entry.role === "director";
            })[0].fill
          ),
        ]
          .map((a) => a[1])
          .map((obj) => Object.values(obj).length),
      ].reduce((partial_sum, a) => partial_sum + a, 0);
      let filled = answers === 24;
      return [
        (
          Object.entries(
            dataset.filter((entry) => {
              return entry.role === "director";
            })[0].fill
          )
            .map(([key, value]) => {
              return (
                Object.values(value).reduce((a, b) => a + b, 0) /
                Object.values(value).length
              );
            })
            .reduce((a, b) => a + b, 0) / map.length
        ).toFixed(1),
        filled,
      ];
    } else {
      return ["N/A", false];
    }
  }

  function createAverage(numbers) {
    try {
      return numbers.reduce((a, b) => a + b, 0) / numbers.length;
    } catch (e) {
      return "N/A";
    }
  }

  function calculateAveragedSummaryTeam(dataset) {
    const workingSet = JSON.parse(JSON.stringify(dataset));
    // initial condition, if any
    if (
      workingSet.filter((entry) => entry.role === "team_member" && entry.fill)
        .length > 0
    ) {
      const numbers = workingSet
        .filter((entry) => entry.role === "team_member" && entry.fill)
        .map((entry) => {
          entry.fill = Object.values(entry.fill).map(
            (value) => Object.values(value).reduce((a, b) => a + b, 0) / 3
          ); // extract values out of fill
          entry.fill =
            entry.fill.reduce((a, b) => a + b, 0) / entry.fill.length;
          return entry;
        })
        .map((entry) => entry.fill);
      const average = (
        numbers.reduce((a, b) => a + b, 0) / numbers.length
      ).toFixed(1);
      return average;
    }
    return "N/A";
  }

  function calculateIndex(self, comparison) {
    if (![self, comparison].includes("N/A")) {
      const index = ((comparison / self) * 100).toFixed(0);
      return [index, index >= 125 ? "green" : index <= 75 ? "red" : ""];
    } else {
      return ["N/A", ""];
    }
  }

  return (
    <div className={styles.result}>
      <div
        className={[
          styles.title,
          !minimized || showPerfChart ? styles.collapsible : "",
          printMode ? styles.printMode : "",
        ].join(" ")}
        onClick={() => {
          if (minimized && !showPerfChart) {
            setMinimized(false);
            setShowPerfChart(true);
          } else {
            setMinimized(true);
            setShowPerfChart(false);
          }
        }}
      >
        {completion < 100 && <img src="/check-empty.svg" alt="" />}
        {completion === 100 && <img src="/check.svg" alt="" />}
        {document[1].name}{" "}
        {completion < 100 && `(${completion.toFixed(0)}% completed)`}
        <div className={styles.buttons}>
          {(!minimized || showPerfChart) &&
            currentUser !== null &&
            currentUserData &&
            currentUserData.role === "admin" && (
              <img
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  triggerPrint(id);
                }}
                src="/print.svg"
                alt=""
              />
            )}
          {(!minimized || showPerfChart) && <img src="/collapse.svg" alt="" />}
          {minimized && !showPerfChart && <img src="/expand.svg" alt="" />}
        </div>
        {printMode && (
          <img src="/logo_bw.svg" alt="" className={styles.miniLogo} />
        )}
      </div>
      <div
        className={[styles.breakdown, printMode ? styles.printMode : ""].join(
          " "
        )}
      >
        <div className={styles.breakdownTitle}>
          <div></div>
          <div className={styles.accent}>SELF</div>
          <div>DIRECTOR</div>
          <div className={styles.accent}>TEAM</div>
          <div className={styles.accent}></div>
        </div>
        {relevantDataset && (
          <div className={styles.breakdownShort}>
            <div>
              Averaged summary
              {minimized && !printMode && (
                <button
                  onClick={() => {
                    setMinimized(false);
                  }}
                >
                  <img src="/zoom.svg" alt="" /> SHOW FULL OVERVIEW
                </button>
              )}
              {!minimized && !printMode && (
                <button
                  onClick={() => {
                    setMinimized(true);
                  }}
                >
                  <img src="/collapse_dark.svg" alt="" /> HIDE FULL OVERVIEW
                </button>
              )}
            </div>
            <div className={styles.accent}>
              {calculateAveragedSummarySelf(relevantDataset)}
            </div>
            <div>
              {calculateAveragedSummaryDirector(relevantDataset)[0]}
              {!calculateAveragedSummaryDirector(relevantDataset)[1] ? (
                <img src="/warning.svg" alt="" />
              ) : (
                ""
              )}
            </div>
            <div
              className={[
                styles.sep,
                calculateIndex(
                  calculateAveragedSummarySelf(relevantDataset),
                  calculateAveragedSummaryDirector(relevantDataset)
                )[1],
              ].join(" ")}
            >
              {
                calculateIndex(
                  calculateAveragedSummarySelf(relevantDataset),
                  calculateAveragedSummaryDirector(relevantDataset)[0]
                )[0]
              }
            </div>
            <div className={styles.accent}>
              {calculateAveragedSummaryTeam(relevantDataset)}
            </div>
            <div
              className={[
                styles.sep,
                styles.accent,
                calculateIndex(
                  calculateAveragedSummarySelf(relevantDataset),
                  calculateAveragedSummaryTeam(relevantDataset)
                )[1],
              ].join(" ")}
            >
              {
                calculateIndex(
                  calculateAveragedSummarySelf(relevantDataset),
                  calculateAveragedSummaryTeam(relevantDataset)
                )[0]
              }
            </div>
            <div className={styles.accent}></div>
          </div>
        )}
        {relevantDataset && (!minimized || printMode) && (
          <div className={styles.spacer}></div>
        )}
        {relevantDataset &&
          (!minimized || printMode) &&
          map.map((entry, index) => {
            return (
              <Eval
                key={index}
                evalKey={entry[0]}
                data={relevantDataset}
                map={map}
                id={id}
                last={index + 1 === map.length}
                printMode={printMode}
              />
            );
          })}
        {!printMode && (
          <div
            className={[
              styles.perfChartExpander,
              showPerfChart ? styles.expanded : "",
            ].join(" ")}
          >
            {!showPerfChart && (
              <button
                onClick={() => {
                  setShowPerfChart(true);
                }}
              >
                <img src="/chart.svg" alt="" />
                SHOW PERFORMANCE CHART
              </button>
            )}
            {showPerfChart && (
              <button
                onClick={() => {
                  setShowPerfChart(false);
                }}
              >
                <img src="/collapse_dark.svg" alt="" />
                HIDE PERFORMANCE CHART{" "}
              </button>
            )}
          </div>
        )}
        {showPerfChart && !printMode && (
          <PerfChart relevantData={relevantDataset} />
        )}
      </div>
    </div>
  );
}
