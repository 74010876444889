import {useAuth} from '../../../contexts/AuthContext';
import {useLocation, useHistory} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import styles from './Navigator.module.scss';

export default function Navigator() {
  const {currentUser, currentUserData, currentCanonical, canonicals} =
    useAuth();
  const location = useLocation();
  const history = useHistory();
  const [navValue, setNavValue] = useState();

  const options = {
    team_member: [['EVALUATION', '/assessment']],
    manager: [
      ['EVALUATION', '/assessment'],
      ['DASHBOARD', '/dashboard'],
    ],
    get director() {
      if (currentUserData && canonicals && currentCanonical) {
        const company = encodeURI(currentUserData.props.Company);
        const bucket = encodeURI(currentUserData.bucket);
        const canonicalData = JSON.parse(
          canonicals.canonicals.filter((c) => c[0] === currentCanonical)[0][1]
        );
        let readable = canonicalData.company_name;
        if (canonicalData.managers.length === 1) {
          readable = canonicalData.managers[0];
        }
        return [
          ['EVALUATION', '/assessment'],
          [
            `${readable.toUpperCase()} REPORT`,
            '/query',
            `/query/new/${JSON.stringify([
              {key: 'Company', value: company},
              {in: bucket},
            ])}`,
          ],
        ];
      } else {
        return [['EVALUATION', '/assessment']];
      }
    },
    admin: [
      ['HOME', '/admin'],
      ['REPORTS', '/query'],
      ['UTILITIES', '/utilities'],
      ['TEAMS', '/manageTeams'],
      ['VAULT', '/vault'],
      ['BACKUP', '/backup'],
    ],
  };

  const pictures = [
    ['/assessment', '/evaluation.svg'],
    ['/dashboard', '/home.svg'],
    ['/admin', '/home.svg'],
    ['/query', '/summary.svg'],
    ['/utilities', '/utils.svg'],
    ['/vault', '/update.svg'],
    ['/manageTeams', '/groups.svg'],
    ['/backup', '/backup.svg'],
  ];

  useEffect(() => {
    if (currentUserData) {
      const newPath = '/' + location.pathname.split('/')[1];
      setNavValue(newPath);
    }
  }, [location, currentUserData]);

  return (
    <div className={[styles.navigator, 'noprint'].join(' ')}>
      {currentUser && currentUserData && navValue && (
        <img
          className={styles.icon}
          src={pictures.filter((val) => val[0] === navValue)[0][1]}
          alt="Icon"
        />
      )}
      {currentUser && currentUserData && navValue && (
        <select
          className={
            options[currentUserData.role].length === 1 ? styles.single : ''
          }
          value={navValue}
          onChange={(e) => {
            const match = options[currentUserData.role].filter(
              (opt) => opt[1] === e.target.value
            )[0];
            if (match.length === 2) {
              history.push(match[1]);
            }
            if (match.length === 3) {
              history.push(match[2]);
            }
          }}>
          {options[currentUserData.role].map((entry, index) => {
            return (
              <option key={index} value={entry[1]}>
                {entry[0]}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
}
