import styles from "./Footer.module.scss";

export default function Footer({ children }) {
    return (
        <div className={[styles.footer, "noprint"].join(" ")}>
            <div>
                ©{new Date().getFullYear()} Romar Learning Solutions
            </div>
            {
                children && <div>{children}</div>
            }
        </div>
    )
}