import {useAuth} from '../../contexts/AuthContext';

import Header from '../header/Header';
import Message from '../message/Message';
import Container from '../container/Container';
import TiledButtons from '../common/tiledButtons/tiledButtons';
import TButton from '../common/tiledButtons/tButton/tButton';

export default function Admin() {
  const {currentUserData} = useAuth();

  return (
    <>
      <Header />
      <Container>
        {currentUserData && (
          <>
            <Message>
              <h2>
                Welcome, <strong>{currentUserData.name}</strong>!
              </h2>
              <p>
                You are an <i>administrator</i>. Please select one of the
                options below.
              </p>
            </Message>
            <TiledButtons>
              <TButton caption="Reports" icon="./summary.svg" url="/query" />
              <TButton caption="Vault" icon="./update.svg" url="/vault" />
              <TButton
                caption="Manage Teams"
                icon="./groups.svg"
                url="/manageTeams"
              />
              <TButton
                caption="Utilities"
                icon="./utils.svg"
                url="/utilities"
              />
            </TiledButtons>
          </>
        )}
      </Container>
    </>
  );
}
