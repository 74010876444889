import Footer from "../footer/Footer";
import styles from "./Container.module.scss";

export default function Container({ children }) {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.pusher}>
                    {children}
                </div>
                <Footer />
            </div>
        </>
    )
}