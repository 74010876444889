import {csvExport} from '../../../functions/csvExport';
import {copyToClipboard} from '../../../functions/copyToClipboard';
/*import {useFavorites} from '../../../contexts/FavoritesContext';*/
import styles from './Conditions.module.scss';
import {useHistory} from 'react-router-dom';
import {useAuth} from '../../../contexts/AuthContext';
import {useEffect} from 'react';

export default function Conditions({queryOutput, query}) {
  const history = useHistory();
  const {currentUserData} = useAuth();

  useEffect(() => {
    console.log(currentUserData);
  }, [currentUserData]);

  return (
    <div className={styles.conditions}>
      <div>
        {currentUserData && currentUserData.role === 'admin' && (
          <button
            onClick={() => {
              history.push(
                '/redirect/' + encodeURI(JSON.stringify({url: '/query'}))
              );
            }}>
            <img src="./back.svg" alt="" />
          </button>
        )}
        {Object.keys(queryOutput).length > 1 && (
          <p>
            Report on <strong>{Object.keys(queryOutput).length}</strong>{' '}
            managers:
          </p>
        )}
        {query &&
          query
            .filter((q) => q.key)
            .map((entry, index) => {
              return (
                <div className={styles.condition} key={index}>
                  <strong>{entry.key}:</strong> {entry.value}
                </div>
              );
            })}
        {query && (
          <div className={styles.condition}>
            <strong>Collection:</strong> {query.filter((q) => q.in)[0].in}
          </div>
        )}
      </div>
      <div>
        <img
          src="/share.svg"
          alt=""
          onClick={async () => {
            await copyToClipboard(window.location.href);
            alert(
              'The URL of this report has been successfully copied to your clipboard!'
            );
          }}
        />
        {/*!isInFavorites(query) && (
          <img
            src="/favorite_off.svg"
            alt=""
            onClick={async () => {
              const newName = prompt(
                'What would be the name of this shortcut?'
              );
              if (newName.trim() !== '' && newName.length >= 5) {
                await addToFavorites(query, newName);
              } else {
                alert(
                  'Name of a shortcut cannot be empty and needs to consist of at least 5 characters.'
                );
              }
            }}
          />
          )*/}
        {/*isInFavorites(query) && (
					<img
						src="/favorite_on.svg"
						alt=""
						onClick={async () => {
							await removeFromFavorites(query);
						}}
					/>
					)*/}
        <img
          src="/save.svg"
          alt=""
          style={{pointerEvents: 'none', opacity: 0.05, display: 'none'}}
        />
        <img
          src="/backup.svg"
          alt=""
          onClick={async () => {
            const csvOutput = await csvExport(queryOutput);

            console.log('retrieved output?', csvOutput);

            var link = document.createElement('a');
            link.setAttribute(
              'href',
              'data:text/csv;charset=utf-8,' + encodeURIComponent(csvOutput)
            );
            link.target = '_blank';
            link.setAttribute('download', 'romar_export.csv');
            document.body.appendChild(link);

            link.click();
          }}
        />
      </div>
    </div>
  );
}
