import styles from './RocChart.module.scss';

export default function RocChart({values}) {
  function getChartHeights(values) {
    const floatValues = values
      .filter((val) => !isNaN(val))
      .map((val) => parseFloat(val));
    const max = Math.max(...floatValues);
    return values.map((value) => {
      if (value !== 'N/A' && !isNaN(value))
        return (parseFloat(value) / max) * 100;
      return 0;
    });
  }
  function parse(value) {
    if (value !== 'N/A' && !isNaN(value)) return value;
    return 'N/A';
  }

  return (
    values && (
      <div className={styles.rocChart}>
        <div className={styles.barContainer}>
          <div
            className={styles.bar}
            style={{height: getChartHeights(values)[0] + '%'}}>
            <div>{parse(values[0])}</div>
          </div>
        </div>
        <div className={styles.barContainer}>
          <div
            className={styles.bar}
            style={{height: getChartHeights(values)[1] + '%'}}>
            <div>{parse(values[1])}</div>
          </div>
        </div>
        <div className={styles.barContainer}>
          <div
            className={styles.bar}
            style={{height: getChartHeights(values)[2] + '%'}}>
            <div>{parse(values[2])}</div>
          </div>
        </div>
        <div className={styles.subtitle}>SELF</div>
        <div className={styles.subtitle}>DIRECTOR</div>
        <div className={styles.subtitle}>TEAM</div>
      </div>
    )
  );
}
