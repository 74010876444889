import styles from "./UniversalQuery.module.scss";
import { useState, useEffect } from "react";

import { firestore } from "../../../firebase";

export default function UniversalQuery({ setQuery }) {
  const [availableCollections, setAvailableCollections] = useState();

  useEffect(() => {
    const obtain = async () => {
      const collections = (await firestore.collection("users").get()).docs.map(
        (d) => [d.id, d.data()]
      );
      setAvailableCollections(collections);
    };
    obtain();
  }, []);

  useEffect(() => {
    console.log("---COLLECTIONS---");
    console.log(availableCollections);
    console.log("---END COLLECTIONS---");
  }, [availableCollections]);

  return (
    <div className={styles.universalQuery}>
      <h3>
        <img src="/summary.svg" alt="" /> Select a report
      </h3>
      {availableCollections &&
        availableCollections
          .filter((c) => c[0] !== "Administrators")
          .sort((a, b) => b[1].company_name.localeCompare(a[1].company_name))
          // .sort((a, b) => a[1].managers.length < b[1].managers.length)
          .sort((a, b) => {
            function getTimeVal(collection) {
              return collection[0]
                .split(" ")
                .slice(-1)
                .toString()
                .split("-")
                .reverse()
                .map((a, b) => (b === 1 ? a / 100 : a))
                .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            }
            return getTimeVal(a) < getTimeVal(b);
          })
          .map((collection, index) => {
            return (
              <div className={styles.report} key={index}>
                <div>
                  {collection[1]["managers"].length === 1 && (
                    <img src="./singlereport.svg" alt="" />
                  )}
                  {collection[1]["managers"].length > 1 && (
                    <img src="./multireport.svg" alt="" />
                  )}
                </div>
                <div>
                  {collection[1]["managers"].length > 1 && (
                    <>
                      <h4>{collection[1]["company_name"]}</h4>
                      {collection[1]["managers"].length <= 3 && (
                        <p>{collection[1]["managers"].join(", ")}</p>
                      )}
                      {collection[1]["managers"].length > 3 && (
                        <p>
                          {collection[1]["managers"]
                            .filter((c, i) => i < 3)
                            .join(", ")}{" "}
                          + {collection[1]["managers"].length - 3} managers
                        </p>
                      )}
                    </>
                  )}
                  {collection[1]["managers"].length === 1 && (
                    <>
                      <h4>{collection[1]["managers"][0]}</h4>
                      <p>{collection[1]["company_name"]}</p>
                    </>
                  )}
                </div>
                <button
                  onClick={() => {
                    setQuery([
                      {
                        key: "Company",
                        value: collection[1]["company_name"],
                      },
                      { in: collection[0] },
                    ]);
                  }}
                >
                  <img src="./zoom.svg" alt="" /> VIEW THIS REPORT
                </button>
              </div>
            );
          })}
    </div>
  );
}
