import { functions } from "./../../firebase";
import styles from "./NewTeam.module.scss";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function NewTeam() {
  const history = useHistory();

  // key section
  const [key, setKey] = useState("");
  const [keyIncorrect, setKeyIncorrect] = useState(false);
  const [step, setStep] = useState(1);

  // manager section
  const [manFullName, setManFullName] = useState("");
  const [company, setCompany] = useState("");
  const [territory, setTerritory] = useState("");
  const [manEmail, setManEmail] = useState("");
  const [manPassword, setManPassword] = useState("");
  const [manEmailIncorrect, setManEmailIncorrect] = useState(false);

  // director section
  const [dirFullName, setDirFullName] = useState("");
  const [dirEmail, setDirEmail] = useState("");
  const [dirEmailIncorrect, setDirEmailIncorrect] = useState(false);

  // team section
  const [team, setTeam] = useState([]);
  const [newTeam, setNewTeam] = useState({
    name: "",
    email: "",
  });
  const [incorrectTeam, setIncorrectTeam] = useState([]);

  // functions
  const verifyKey = functions.httpsCallable("verifyKey");
  const checkEmails = functions.httpsCallable("checkEmails");
  const createNewTeam = functions.httpsCallable("createNewTeam");

  const [loading, setLoading] = useState(false);

  // local functions
  const genDirPass = () => {
    return "ROMAR360";
  };

  const genTeamPass = () => {
    return "ROMAR360";
  };

  return (
    <>
      {loading && (
        <div className={styles.obfuscator}>
          <img src="loading-dark.svg" alt="" />
        </div>
      )}
      <div
        className={[styles.content, loading ? styles.loading : ""].join(" ")}
      >
        <img src="./logo.svg" alt="" />
        <div
          className={[styles.section, step !== 1 ? styles.hiddenStep : ""].join(
            " "
          )}
        >
          <h2>1</h2>
          <h3>Please enter access key</h3>
          <h4>
            Please enter the access key, and click <strong>Continue</strong>.
            The verification might take up to ten seconds.
          </h4>
          <div className={styles.singleInput}>
            <input
              type="text"
              className={keyIncorrect ? styles.wrongCreds : ""}
              value={key}
              onChange={(e) => {
                setKeyIncorrect(false);
                setKey(e.target.value);
              }}
              placeholder="Please enter the access key"
            ></input>
            <button
              onClick={() => {
                if (!loading) {
                  console.log("Access key check trigger.");
                  setLoading(true);
                  verifyKey({ key: key }).then((result) => {
                    if (result.data.correct) {
                      console.log(result);
                      setLoading(false);
                      setStep(2);
                    } else {
                      setLoading(false);
                      alert(
                        `"${key}" is not a valid access key. Please try again.`
                      );
                      setKeyIncorrect(true);
                    }
                  });
                }
              }}
              disabled={key.trim() === "" || loading}
            >
              Continue
            </button>
          </div>
        </div>
        <div
          className={[styles.section, step !== 2 ? styles.hiddenStep : ""].join(
            " "
          )}
        >
          <h2>2</h2>
          <h3>Who are you?</h3>
          <h4>
            Please enter your information below. Please remember your email and
            password. They will be used to login for future sessions.
          </h4>
          <div className={styles.form}>
            <div>
              <div>Full name:</div>
              <div>
                <input
                  value={manFullName}
                  onChange={(e) => {
                    setManFullName(e.target.value);
                  }}
                  type="text"
                  placeholder="Please enter your full name"
                />
              </div>
            </div>
            <div>
              <div>Company:</div>
              <div>
                <input
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                  type="text"
                  placeholder="Please enter your company's name"
                />
              </div>
            </div>
            <div>
              <div>Territory / District:</div>
              <div>
                <input
                  value={territory}
                  onChange={(e) => {
                    setTerritory(e.target.value);
                  }}
                  type="text"
                  placeholder='If no territory or district, please add "None"'
                />
              </div>
            </div>
            <div>
              <div>E-mail address:</div>
              <div>
                <input
                  className={manEmailIncorrect ? styles.wrongCreds : ""}
                  value={manEmail}
                  onChange={(e) => {
                    setManEmail(e.target.value);
                    setManEmailIncorrect(false);
                  }}
                  type="text"
                  placeholder="Please enter your e-mail"
                />
              </div>
            </div>
            <div>
              <div>Create a password:</div>
              <div>
                <input
                  value={manPassword}
                  onChange={(e) => {
                    setManPassword(e.target.value);
                  }}
                  type="text"
                  minLength="8"
                  placeholder="Input password, at least 8 characters"
                />
                {false && (
                  <p>
                    Please enter a password with 8 to 16 characters of length
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.nav}>
            <div>
              <button
                className={styles.cont}
                disabled={
                  manFullName.trim() === "" ||
                  company.trim() === "" ||
                  company.trim().length <= 2 ||
                  territory.trim() === "" ||
                  manEmail.trim() === "" ||
                  manPassword.trim() === "" ||
                  manPassword.length < 8 ||
                  loading
                }
                onClick={() => {
                  if (!loading) {
                    console.log("Triggering confirmation of manager data.");
                    setLoading(true);
                    checkEmails({
                      emails: [manEmail],
                      allowDuplicates: true,
                    }).then((result) => {
                      setLoading(false);
                      console.log(result);
                      if (result.data.problems.length > 0) {
                        alert(
                          `The e-mail address "${manEmail}" is not a valid e-mail address or an user with this e-mail is already registered. Please try a different e-mail address.`
                        );
                        setManEmailIncorrect(true);
                      } else {
                        setStep(3);
                      }
                    });
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div
          className={[styles.section, step !== 3 ? styles.hiddenStep : ""].join(
            " "
          )}
        >
          <h2>3</h2>
          <h3>Who is your immediate manager?</h3>
          <h4>Please enter the information about your immediate manager.</h4>
          <div className={styles.form}>
            <div>
              <div>Full name:</div>
              <div>
                <input
                  type="text"
                  value={dirFullName}
                  onChange={(e) => {
                    setDirFullName(e.target.value);
                  }}
                  placeholder="Please enter immediate manager's full name"
                />
              </div>
            </div>
            <div>
              <div>E-mail address:</div>
              <div>
                <input
                  className={dirEmailIncorrect ? styles.wrongCreds : ""}
                  type="text"
                  value={dirEmail}
                  onChange={(e) => {
                    setDirEmail(e.target.value);
                    setDirEmailIncorrect(false);
                  }}
                  placeholder="Please enter immediate manager's e-mail address"
                />
              </div>
            </div>
          </div>
          <div className={styles.nav}>
            <div style={{ width: "200px" }}>
              <button
                style={{ width: "190px" }}
                onClick={() => {
                  setStep(2);
                }}
              >
                Go back
              </button>
            </div>
            <div>
              <button
                className={styles.cont}
                disabled={
                  dirFullName.trim() === "" || dirEmail.trim() === "" || loading
                }
                onClick={() => {
                  if (!loading) {
                    console.log("Triggering confirmation of director data.");
                    setLoading(true);
                    checkEmails({
                      emails: [dirEmail],
                      allowDuplicates: true,
                    }).then((result) => {
                      setLoading(false);
                      console.log(result);
                      if (result.data.problems.length > 0) {
                        alert(
                          `The e-mail address "${dirEmail}" is not a valid e-mail address or an user with this e-mail is already registered. Please try a different e-mail address.`
                        );
                        setDirEmailIncorrect(true);
                      } else {
                        setStep(4);
                      }
                    });
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <div
          className={[styles.section, step !== 4 ? styles.hiddenStep : ""].join(
            " "
          )}
        >
          <h2>4</h2>
          <h3>Who is in your team?</h3>
          <h4>
            Please add names and emails for your direct reports. Once you have
            added all direct reports, click "CREATE NEW EVALUATION"
          </h4>
          <div className={styles.team}>
            <ol>
              {team.length === 0 && (
                <li>
                  <b>
                    No team members were added to your team yet, please use the
                    form below to add new team members.
                  </b>
                </li>
              )}
              {team.length > 0 &&
                team.map((t, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        incorrectTeam.includes(t.email.toLowerCase())
                          ? styles.incorrect
                          : ""
                      }
                    >
                      <strong>{t.name}</strong> ({t.email.toLowerCase()}){" "}
                      <img
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this team member?"
                            )
                          ) {
                            setTeam((prevTeam) => {
                              return prevTeam.filter((v, i) => {
                                return i !== index;
                              });
                            });
                          }
                        }}
                        src="./delete.svg"
                        alt=""
                      />
                    </li>
                  );
                })}
            </ol>
            <div className={styles.bound}>
              <h3>Add a direct report</h3>
              <div className={styles.form}>
                <div>
                  <div>Full name:</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Please enter team member's full name"
                      value={newTeam.name}
                      onChange={(e) => {
                        setNewTeam((prevValue) => {
                          return {
                            name: e.target.value,
                            email: prevValue.email,
                            territory: prevValue.territory,
                            region: prevValue.region,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>E-mail address:</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Please enter team member's e-mail address"
                      value={newTeam.email}
                      onChange={(e) => {
                        setNewTeam((prevValue) => {
                          return {
                            name: prevValue.name,
                            email: e.target.value.toLowerCase(),
                            territory: prevValue.territory,
                            region: prevValue.region,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <button
                disabled={
                  newTeam.name.trim() === "" ||
                  newTeam.email.trim() === "" ||
                  loading
                }
                onClick={() => {
                  setTeam((prevValue) => {
                    return [...prevValue, newTeam];
                  });
                  setNewTeam({
                    name: "",
                    email: "",
                  });
                }}
              >
                Add this direct report
              </button>
            </div>
          </div>
          <div className={styles.nav}>
            <div style={{ width: "200px" }}>
              <button
                style={{ width: "190px" }}
                onClick={() => {
                  setStep(3);
                }}
              >
                Go back
              </button>
            </div>
            <div>
              <button
                className={styles.cont}
                disabled={team.length === 0 || loading}
                onClick={() => {
                  if (!loading) {
                    setLoading(true);
                    checkEmails({
                      emails: team.map((t) => t.email),
                      allowDuplicates: true,
                    }).then((result) => {
                      if (result.data.problems.length > 0) {
                        alert(
                          `Some of the team members you specified contain incorrect e-mails or e-mails that are already in use by existing users. Please review your team.`
                        );
                        setIncorrectTeam(
                          result.data.problems.map((p) => p.email)
                        );
                        setLoading(false);
                      } else {
                        const data = {
                          company: company,
                          territory: territory,
                          manager: { manFullName, manEmail, manPassword },
                          director: { dirFullName, dirEmail },
                          team: team,
                          dirPass: genDirPass(),
                          teamPass: genTeamPass(),
                        };

                        console.log(data);

                        createNewTeam({ data: data }).then((result) => {
                          console.log("Team creation result?", result);
                          //alert('Your team was created!');
                          setLoading(false);
                          setStep(5);
                        });
                      }
                    });
                  }
                }}
              >
                Create new evaluation
              </button>
            </div>
          </div>
        </div>
        {true && (
          <div
            className={[
              styles.section,
              step !== 5 ? styles.hiddenStep : "",
            ].join(" ")}
          >
            <h2>5</h2>
            <h3>Your new evaluation has been created.</h3>
            <h4>
              {manFullName}, you can now be evaluated in the Romar 360 system.
              Your team consists of <strong>{team.length + 1} persons</strong>.
            </h4>
            <h4 style={{ marginBottom: "0px" }}>
              Further instructions have been sent to yours and your teams'
              e-mail addresses.
            </h4>
            <div className={styles.nav}>
              <div>
                <button
                  onClick={() => {
                    history.push("/login/");
                  }}
                >
                  <img src="./singlereport_white.svg" alt="" />
                  Proceed to sign in
                </button>
              </div>
            </div>
          </div>
        )}
        {false && (
          <div
            className={[
              styles.section,
              step !== 5 ? styles.hiddenStep : "",
            ].join(" ")}
          >
            <h2>5</h2>
            <h3>Your new evaluation has been created.</h3>
            <h4>
              {manFullName}, you can now be evaluated in the Romar 360 system.
              Your team consists of <strong>{team.length + 1} persons</strong>.
              They can log into the Romar 360 platform by using their e-mail
              address and password <strong>ROMAR360</strong>.
            </h4>
            <h4 style={{ marginBottom: "0px" }}>
              Please remember to let your team know that they are invited to
              evaluate you. <strong>You can use the button below</strong> to
              automatically create an invitation e-mail that's ready to send.
            </h4>
            <div className={styles.nav}>
              <div>
                <button
                  onClick={() => {
                    const subject = encodeURI(`Evaluate me with Romar 360`);
                    const body = [
                      `Hello!`,
                      `You're invited to evaluate me with Romar 360 - https://romar360.mtxgrp.com/login -`,
                      `To log in, use your e-mail and password: ROMAR360`,
                    ]
                      .map((line) => encodeURI(line))
                      //.join('%0D%0A')
                      .join("%20");
                    const recipients = [
                      dirEmail,
                      ...team.map((t) => t.email),
                    ].join(",");
                    console.log(recipients);
                    window.open(
                      `mailto:${recipients}?subject=${subject}&body=${body}`
                    );
                  }}
                >
                  <img src="./mail.svg" alt="" />
                  Send the invitation to your team
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
