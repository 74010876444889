import styles from './Output.module.scss';
import Result from './result/Result';

import {useEffect, useState} from 'react';

export default function Output({queryOutput, printMode, setPrintMode}) {
  const [exclude, setExclude] = useState([]);

  function excludeAllBut(id) {
    setExclude(Object.keys(queryOutput).filter((k) => k !== id));
  }

  function triggerPrint(id) {
    excludeAllBut(id);
    setPrintMode(true);

    setTimeout(function () {
      try {
        document.execCommand('print', false, null);
      } catch (e) {
        window.print();
      }

      setTimeout(function () {
        setExclude([]);
        setPrintMode(false);
      }, 100);
    }, 100);
  }

  useEffect(() => {
    console.log(exclude);
  }, [exclude]);

  return (
    <div
      className={[styles.queryOutput, printMode ? styles.inPrint : ''].join(
        ' '
      )}>
      <div>
        {queryOutput &&
          Object.entries(queryOutput)
            .filter(([a, b]) => exclude.indexOf(a) === -1)
            .sort(([, a], [, b]) => {
              const completionA =
                a[2].filter((entry) => entry.data.fill && entry.data.fill[a[0]])
                  .length / a[2].length;
              const completionB =
                b[2].filter((entry) => entry.data.fill && entry.data.fill[b[0]])
                  .length / b[2].length;
              return completionA > completionB ? -1 : 1;
            })
            .map(([key, value], index) => {
              return (
                <Result
                  key={index}
                  index={index + 1}
                  id={key}
                  document={value}
                  triggerPrint={triggerPrint}
                  printMode={printMode}
                />
              );
            })}
      </div>
    </div>
  );
}
