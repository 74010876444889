import React, {useContext, useState} from 'react';
import {useAuth} from './AuthContext';
import {firestore} from '../firebase';

const VaultContext = React.createContext();

export function useVault() {
	return useContext(VaultContext);
}

export function VaultProvider({children}) {
	const {currentUser} = useAuth();
	const [vaultData, setVaultData] = useState();
	const vaultRef = firestore.collection('variables').doc('vault');
	const usersContainerRef = firestore.collection('users');

	async function pullVaultData() {
		if (currentUser) {
			const vault = await vaultRef.get();
			setVaultData(vault.data());
		} else {
			console.log('Will not be attempting to pull vault data - unauthorized.');
		}
	}

	async function resetVault() {
		try {
			await vaultRef.update({timestamp: '', vault: []});
			setVaultData({timestamp: '', vault: []}); // there's no need to put the empty vault data from the server if we know exactly what it is
			return true;
		} catch (error) {
			return error;
		}
	}

	function pullSourceData() {
		return new Promise(async (resolve, reject) => {
			Promise.all(
				(await usersContainerRef.get()).docs.map(async (doc) => {
					return await firestore
						.collection('/users/' + doc.id + '/users')
						.get();
				})
			).then((results) => {
				let sourceData = [];

				results.forEach(async (result) => {
					result.forEach(async (doc) => {
						sourceData.push(doc.data());
					});
				});

				sourceData = sourceData.filter((entry) => {
					return (
						'fill' in entry && 'relevant_to' in entry && 'submitted_ar' in entry
					);
				});

				resolve(sourceData);
			});
		});
	}

	async function updateVault(data) {
		await vaultRef.update({timestamp: Date.now(), vault: data});
		return true;
	}

	function vaultTimestamp() {
		if (vaultData && vaultData.timestamp && vaultData.timestamp !== '') {
			return vaultData.timestamp;
		} else {
			return false;
		}
	}

	function vaultDataPresent() {
		return vaultData && vaultData !== null;
	}

	const value = {
		vaultData,
		pullVaultData,
		pullSourceData,
		resetVault,
		updateVault,
		vaultTimestamp,
		vaultDataPresent,
	};

	return (
		<VaultContext.Provider value={value}>{children}</VaultContext.Provider>
	);
}
