import {useVault} from '../../../contexts/VaultContext';
import styles from './VaultView.module.scss';

export default function VaultView() {
	const {vaultData} = useVault();
	const map = [
		'Mindset',
		'Expectations',
		'Skill and Knowledge Development',
		'Cultivating Motivation',
		'Feedback',
		'Coaching Process',
		'Distance Coaching',
		'Trust',
	];

	function average(array) {
		return parseFloat(
			array.map((element) => parseFloat(element)).reduce((a, b) => a + b, 0) /
				array.length
		).toFixed(1);
	}

	return (
		<>
			{vaultData &&
				Object.keys(vaultData).includes('vault') &&
				Object.keys(vaultData.vault).length > 0 && (
					<div className={styles.vaultView}>
						<div className={styles.firstRow}></div>
						<div className={[styles.firstRow, styles.darker].join(' ')}>
							MANAGER <p>EVALUATES SELF</p>
						</div>
						<div className={styles.firstRow}>
							DIRECTOR <p>EVALUATES MANAGER</p>
						</div>
						<div className={[styles.firstRow, styles.darker].join(' ')}>
							TEAM MEMBER <p>EVALUATES MANAGER</p>
						</div>
						{map.map((entry, index) => {
							return (
								<>
									<div
										className={[
											styles.question,
											index < map.length - 1 ? '' : styles.lastRow,
										].join(' ')}>
										{entry}
									</div>
									<div
										className={[
											styles.score,
											index < map.length - 1 ? '' : styles.lastRow,
											styles.darker,
										].join(' ')}>
										{average(vaultData.vault.self[entry])}
									</div>
									<div
										className={[
											styles.score,
											index < map.length - 1 ? '' : styles.lastRow,
										].join(' ')}>
										{average(vaultData.vault.director[entry])}
									</div>
									<div
										className={[
											styles.score,
											index < map.length - 1 ? '' : styles.lastRow,
											styles.darker,
										].join(' ')}>
										{average(vaultData.vault.team[entry])}
									</div>
								</>
							);
						})}
					</div>
				)}
		</>
	);
}
