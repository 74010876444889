import Header from '../header/Header';
import Message from '../message/Message';
import Container from '../container/Container';
import TiledButtons from '../common/tiledButtons/tiledButtons';
import TButton from '../common/tiledButtons/tButton/tButton';

export default function Utilitites() {
  return (
    <>
      <Header />
      <Container>
        <Message>
          <p>
            To perform administrative back-end tasks, please use one of the
            utilities listed below.
          </p>
        </Message>
        <TiledButtons>
          <TButton
            caption="Romar Vault"
            icon="./update.svg"
            url="/vault"
          />
          <TButton
            caption="Create a backup"
            icon="./backup_outlined.svg"
            url="/backup"
          />
        </TiledButtons>
      </Container>
    </>
  );
}
