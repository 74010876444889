import {functions} from './../../firebase';
import {useState} from 'react';
import styles from './Reset.module.scss';

import Container from '../container/Container';

export default function ResetStart() {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [wrongCreds, setWrongCreds] = useState(false);
  const [wrongMessage, setWrongMessage] = useState('Incorrect credentials.');
  const [success, setSuccess] = useState(false);

  const initializePasswordReset = functions.httpsCallable(
    'initializePasswordReset'
  );

  return (
    <Container>
      {success === false && (
        <div className={styles.resetBox}>
          <div>
            <h2>Forgotten password?</h2>
            <h3>Please enter your e-mail address to continue.</h3>
            <h4>E-mail</h4>
            <input
              type="text"
              placeholder="Please input your e-mail (username)"
              value={username}
              disabled={loading}
              className={wrongCreds ? styles.wrongCreds : ''}
              onChange={(e) => {
                setUsername(e.target.value);
                setWrongCreds(false);
              }}
            />
            {wrongCreds && <p className={styles.incorrect}>{wrongMessage}</p>}
            <hr />
            <button
              disabled={loading}
              className={loading ? styles.loading : ''}
              onClick={() => {
                setLoading(true);
                initializePasswordReset({email: username}).then((result) => {
                  console.log(result);
                  setLoading(false);
                  if (result.data.success === true) {
                    setSuccess(true);
                  } else {
                    setWrongMessage(result.data.error);
                    setWrongCreds(true);
                  }
                });
              }}>
              Continue
              <img
                src="/loading-bright.svg"
                alt=""
                className={styles.loading}
              />
            </button>
            <p
              className={[styles.back, loading ? styles.disabled : ''].join(
                ' '
              )}>
              <a href="./login">Try signing in instead</a>
            </p>
          </div>
        </div>
      )}
      {success === true && (
        <div className={styles.resetBox}>
          <div>
            <h2>Thank you!</h2>
            <h3>Password reset instructions have been sent to your e-mail.</h3>
            <h3 style={{marginTop: '-10px', marginBottom: '0px'}}>
              You can now safely close this tab.
            </h3>
          </div>
        </div>
      )}
    </Container>
  );
}
