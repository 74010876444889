export async function copyToClipboard(input) {
	var dummy = document.createElement("input"),
		text = input;

	document.body.appendChild(dummy);
	dummy.value = text;
	dummy.select();
	document.execCommand("copy");
	document.body.removeChild(dummy);
	return true;
}
