import styles from './Backup.module.scss';
import Header from './../header/Header';
import Container from './../container/Container';
import Message from './../message/Message';

export default function Backup() {
  return (
    <>
      <Header />
      <Container>
        <Message>
          <p>This function is not available yet.</p>
        </Message>
      </Container>
    </>
  );
}
