import styles from './Vault.module.scss';
import {recalculateVault} from '../../functions/recalculateVault';

import Container from '../container/Container';
import Header from '../header/Header';
import Message from '../message/Message';
import TiledButtons from '../common/tiledButtons/tiledButtons';
import TButton from '../common/tiledButtons/tButton/tButton';
import VaultView from './vaultView/VaultView';

import {useVault} from '../../contexts/VaultContext';
import {useAuth} from '../../contexts/AuthContext';
import React, {useEffect, useState} from 'react';

export default function Vault() {
	const {
		pullVaultData,
		pullSourceData,
		resetVault,
		updateVault,
		vaultTimestamp,
		vaultDataPresent,
	} = useVault();
	const {currentUser} = useAuth();
	const [recalculating, setRecalculating] = useState(false);
	const [resetting, setResetting] = useState(false);

	useEffect(() => {
		if (currentUser && !vaultDataPresent()) {
			pullVaultData();
		}
	}, [currentUser, pullVaultData, vaultDataPresent]);

	return (
		<>
			<Header />
			<Container>
				<Message>
					<p>
						ROMAR VAULT is a database of calculated averages and scores derived
						from all evaluations submitted into the system, which are used for
						direct comparison against individual scores to create scoring
						indices.
					</p>
				</Message>
				<TiledButtons>
					<TButton
						caption="Recalculate vault"
						loading={recalculating}
						callback={() => {
							if (
								window.confirm(
									'Do you want to recalculate vault? This might take a while.'
								)
							) {
								setRecalculating(true);
								pullSourceData()
									.then((sourceData) => {
										updateVault(recalculateVault(sourceData))
											.then(() => {
												pullVaultData();
												setRecalculating(false);
												alert('The vault has been successfully recalculated!');
											})
											.catch((error) => {
												console.log(error);
												setRecalculating(false);
											});
									})
									.catch((error) => {
										console.log(error);
										setRecalculating(false);
									});
							}
						}}
						icon="./update.svg"
					/>
					<TButton
						caption="Clear vault"
						loading={resetting}
						callback={() => {
							if (
								window.confirm(
									'Are you sure? Vault data will need to be recalculated afterwards.'
								)
							) {
								setResetting(true);
								resetVault()
									.then(() => {
										setResetting(false);
										alert('The vault has been successfully cleared out!');
									})
									.catch((error) => {
										setResetting(false);
										alert('Error has occured:' + error);
									});
							}
						}}
						icon="./delete.svg"
					/>
				</TiledButtons>
				<p className={styles.last}>
					Last recalculation:&nbsp;
					{vaultTimestamp() === false && <b>N/A</b>}
					{vaultTimestamp() !== false && (
						<b>
							{new Date(vaultTimestamp()).toLocaleDateString()},&nbsp;
							{new Date(vaultTimestamp()).toLocaleTimeString()}
						</b>
					)}
				</p>
				<hr />
				<VaultView />
			</Container>
		</>
	);
}
