export async function csvExport(input) {
	let csvOutput = 'sep=;\n';

	Object.entries(input).forEach(([key, value]) => {
		csvOutput += `${value[1].name} (${value[0]});Evaluators: ${
			value[2].length
		};Submitted: ${
			value[2].filter(
				(entry) =>
					entry.data['submitted_ar'] && entry.data['submitted_ar'].includes(key)
			).length
		}\n`;
		csvOutput += '######################\n';

		csvOutput +=
			'Name;Role;Submitted?;Q1;Q2;Q3;Q4;Q5;Q6;Q7;Q8;Q9;Q10;Q11;Q12;Q13;Q14;Q15;Q16;Q17;Q18;Q19;Q20;Q21;Q22;Q23;Q24\n';

		value[2].forEach((evaluator) => {
			try {
				let q = false;

				if (evaluator.data.fill && evaluator.data.fill[key]) {
					q = [
						0,
						evaluator.data.fill[key]['Mindset'][0],
						evaluator.data.fill[key]['Mindset'][1],
						evaluator.data.fill[key]['Mindset'][2],
						evaluator.data.fill[key]['Expectations'][0],
						evaluator.data.fill[key]['Expectations'][1],
						evaluator.data.fill[key]['Expectations'][2],
						evaluator.data.fill[key]['Skill and Knowledge Development'][0],
						evaluator.data.fill[key]['Skill and Knowledge Development'][1],
						evaluator.data.fill[key]['Skill and Knowledge Development'][2],
						evaluator.data.fill[key]['Cultivating Motivation'][0],
						evaluator.data.fill[key]['Cultivating Motivation'][1],
						evaluator.data.fill[key]['Cultivating Motivation'][2],
						evaluator.data.fill[key]['Feedback'][0],
						evaluator.data.fill[key]['Feedback'][1],
						evaluator.data.fill[key]['Feedback'][2],
						evaluator.data.fill[key]['Coaching Process'][0],
						evaluator.data.fill[key]['Coaching Process'][1],
						evaluator.data.fill[key]['Coaching Process'][2],
						evaluator.data.fill[key]['Distance Coaching'][0],
						evaluator.data.fill[key]['Distance Coaching'][1],
						evaluator.data.fill[key]['Distance Coaching'][2],
						evaluator.data.fill[key]['Trust'][0],
						evaluator.data.fill[key]['Trust'][1],
						evaluator.data.fill[key]['Trust'][2],
					];
				}

				q &&
					(csvOutput += `${evaluator.data.name} (${evaluator.id});${
						evaluator.data.role
					};${
						evaluator.data['submitted_ar'] &&
						evaluator.data['submitted_ar'].includes(key)
							? 'YES'
							: 'NO'
					};${q[1]};${q[2]};${q[3]};${q[4]};${q[5]};${q[6]};${q[7]};${q[8]};${
						q[9]
					};${q[10]};${q[11]};${q[12]};${q[13]};${q[14]};${q[15]};${q[16]};${
						q[17]
					};${q[18]};${q[19]};${q[20]};${q[21]};${q[22]};${q[23]};${q[24]}\n`);
				!q &&
					(csvOutput += `${evaluator.data.name} (${evaluator.id});${
						evaluator.data.role
					};${
						evaluator.data['submitted_ar'] &&
						evaluator.data['submitted_ar'].includes(key)
							? 'YES'
							: 'NO'
					}\n`);
			} catch (error) {
				console.log(error);
			}
		});

		csvOutput += '######################\n\n';
	});

	console.log("generated output?", csvOutput);
	return csvOutput;
}
